<template>
  <div>
    <v-app-bar class="elevation-0 bg-whitesmoke app-bar align-center account-appbar">
      <v-toolbar-title class="primary--text subtitle-1">
        <div class="d-flex align-center">
          <v-btn fab text class="me-2 ms-0" small @click="goToAccountSearch()" v-tooltip="'Back to search page'">
            <v-icon color="primary" large>mdi-arrow-left</v-icon>
          </v-btn>

          <div class="icon-border-custom border-primary me-2" style="border-color: var(--v-primary-base)">
            <v-icon color="primary" left>mdi-account</v-icon>
          </div>
          <div class="subtitle-1 font-weight-medium" style="line-height: 1.2">
            <div>
              <template v-if="accountData.keycloakFirstName">
                <span class="grey--text font-weight-regular text-uppercase label-text">Account:</span>
                {{ accountData.keycloakFirstName }} {{ accountData.keycloakLastName }}
              </template>
              <template v-else>No name registration</template>
              <span class="ms-1">
                <v-chip class="mb-1" color="success" x-small v-if="accountData.keycloakState === 'true'">STATUS: ENABLED</v-chip>
                <v-chip color="error" x-small v-if="accountData.keycloakState === 'false'">STATUS: DISABLED</v-chip>
              </span>
            </div>
            <div class="grey--text text--darken-2">
              <span class="grey--text font-weight-regular text-uppercase label-text">Username:</span>
              <span class="value-text"> {{ accountData.keycloakEmail }}</span>
            </div>
          </div>
        </div>
      </v-toolbar-title>
      <v-spacer />

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" fab elevation="0" color="transparent" @click="$emit('openFormDialog')">
            <v-icon color="primary" large>mdi-folder-plus</v-icon>
          </v-btn>
        </template>
        <span>Create new case</span>
      </v-tooltip>

      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" fab class="user-menu-btn">
            <v-icon large>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="editMainUser()" disabled>
            <v-list-item-title> <v-icon left>mdi-pencil</v-icon>Edit Account </v-list-item-title>
          </v-list-item>
          <v-list-item @click="showDialogUserResendCredentials()">
            <v-list-item-title> <v-icon left>mdi-lock-reset</v-icon>Reset Credentails </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import { mapGetters } from 'vuex';
export default {
  mixins: [SharedFunctions],
  computed: {
    ...mapGetters(['accountData'])
  },
  methods: {
    editMainUser() {
      this.$parent.dialogUser = true;
    },
    showDialogUserResendCredentials() {
      this.$parent.dialogUserResendCredentials = true;
    },
    goToAccountSearch() {
      if (this.$store.state.accountData?.accountSearchedQuery) {
        this.$router.push({ name: 'AccountSearchPage', query: { q: this.$store.state.accountData.accountSearchedQuery } });
      } else {
        this.$router.push({ name: 'AccountSearchPage' });
      }
    }
  }
};
</script>

<style lang="scss">
.account-appbar {
  .label-text {
    font-size: 12px;
  }
  .value-text {
    font-size: 13px;
  }
}
</style>