<template>
  <v-dialog v-model="dialogTransferCase" :width="transferStep ? '800' : '560'" persistent>
    <v-card>
      <v-card-title class="headline primary white--text" primary-title>
        Transfer case <v-spacer />
        <v-btn color="white mt-0" fab text small @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn></v-card-title
      >
      <!-- SEARCH EMAIL STEP -->
      <v-card-text v-if="!transferStep">
        <div class="mt-4 mb-6">Type in the email of the ACCOUNT you would like to transfer this case to:</div>
        <v-form ref="form" v-model="checkEmailValid" v-on:submit.prevent="lookupEmail()">
          <div class="d-flex pb-4">
            <v-text-field
              v-model="email"
              outlined
              dense
              class="me-4"
              :disabled="loadingEmail"
              :rules="emailRules"
              clearable
              placeholder="john.doe@gmail.com"
            ></v-text-field>
            <v-btn color="primary" elevation="0" :disabled="!checkEmailValid || loadingEmail" :loading="loadingEmail" type="submit">
              Find Email
            </v-btn>
          </div>
        </v-form>

        <v-alert dense text type="info" class="mx-4" v-if="emptyResultsEmail"> No accounts found with email/username of {{ email }} </v-alert>

        <transition name="slide-bottom">
          <v-simple-table class="mb-6" hover style="background: whitesmoke" v-if="emailResults && emailResults.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Select</th>
                  <th class="text-left">Full Name</th>
                  <th class="text-left">Email</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><v-checkbox v-model="emailResults[0].selected"></v-checkbox></td>
                  <td>{{ emailResults[0].firstName }} {{ emailResults[0].lastName }}</td>
                  <td>{{ emailResults[0].email }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </transition>
      </v-card-text>
      <!-- TRANSFER CONFIRM STEP -->
      <transition name="fade">
        <v-card-text v-if="transferStep" class="d-flex pt-2 pb-8 mb-4 mt-8 justify-space-between align-center" style="align-items: start">
          <!-- case to transfer -->
          <v-card class="pb-4 info-card align-self-start">
            <v-app-bar class="py-0 mb-2 mt-0 title bg-whitesmoke primary--text" elevation="0" dense color=""
              ><v-icon left color="primary">mdi-folder </v-icon> {{ selectedCase.name }}</v-app-bar
            >
            <div class="text">
              <v-list-item>
                <v-list-item-content class="my-0 py-0">
                  <v-list-item-subtitle class="mb-1">Name & last name</v-list-item-subtitle>
                  <v-list-item-title>{{ selectedCase.firstname }} {{ selectedCase.lastname }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-card>
          <!-- ARROW -->
          <v-avatar class="mx-4" color="primary">
            <v-icon color="white" large>mdi-arrow-right-thick</v-icon>
          </v-avatar>
          <!-- Account to be transfered to -->
          <v-card class="pb-4 info-card align-self-start">
            <v-app-bar class="py-0 mb-2 mt-0 primary white--text title" elevation="0" dense color="primary">
              <v-icon left color="#fff">mdi-account </v-icon> Account to be transfered to</v-app-bar
            >
            <div class="text">
              <v-list-item>
                <v-list-item-content class="my-0 py-0">
                  <v-list-item-subtitle class="mb-1">Username/email</v-list-item-subtitle>
                  <v-list-item-title>{{ accountToBeTransferedTo.email }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-card>
        </v-card-text>
      </transition>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" elevation="0" @click="transferStep = true" :disabled="!canTransfer" v-if="!transferStep">
          Next <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          @click="transfer()"
          :disabled="!canTransfer || loadingTransfer"
          :loading="loadingTransfer"
          v-if="transferStep"
        >
          Transfer <v-icon right>mdi-folder-move-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['dialogTransferCase', 'selectedCase'],
  data() {
    return {
      loadingTransfer: false,
      email: null,
      loadingEmail: false,
      checkingAccountEmail: false,
      emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      checkEmailValid: false,
      emailResults: [],
      emptyResultsEmail: false,
      transferStep: false
    };
  },
  computed: {
    ...mapGetters(['accountData', 'casesData']),
    canTransfer() {
      if (this.emailResults?.length > 0 && this.emailResults[0].selected) return true;
      else return false;
    },
    accountToBeTransferedTo() {
      if (this.emailResults?.length > 0) return this.emailResults[0];
      else return null;
    }
  },
  created() {},
  methods: {
    closeModal() {
      this.$parent.dialogTransferCase = false;
    },
    async lookupEmail() {
      try {
        this.loadingEmail = true;
        this.email = this.email.trim();
        const encodedEmail = encodeURIComponent(this.email);
        const res = await this.$store.dispatch('getAccountByEmail', encodedEmail);
        if (res?.length > 0) {
          this.emailResults = res;
          this.emptyResultsEmail = false;
        } else {
          this.emptyResultsEmail = true;
          this.emailResults = [];
        }
      } finally {
        this.loadingEmail = false;
      }
    },
    async transfer() {
      const bodyToPost = {
        username: this.accountToBeTransferedTo.email,
        uuid: this.accountToBeTransferedTo.id
      };

      try {
        this.loadingTransfer = true;
        const res = await this.$store.dispatch('transferPatientCase', { accountId: this.selectedCase.id, bodyToPost: bodyToPost });
        const caseIdToTransfer = this.$route.params.caseId;
        const caseIdToSelectAfterTransfer = this.casesData.length > 0 ? this.casesData[0].patientCaseId : null;
        this.$store.commit('SET_ACCOUNT_UPDATED', true);
        this.$router
          .push({ name: 'AccountPage', params: { accountId: this.$route.params.accountId, caseId: caseIdToSelectAfterTransfer } })
          .catch(() => 'router catch');
        let payload = {
          open: true,
          text: 'Case successfully transfered',
          color: 'success',
          timeout: 8000,
          customButton: () => {
            this.goToNewCasePage(res, caseIdToTransfer);
          }
        };
        setTimeout(() => this.$store.commit('SET_SNACKBAR', payload), 400);
      } finally {
        setTimeout(async () => {
          this.loadingTransfer = false;
          await this.$store.dispatch('getAccountData', this.$route.params.accountId);
        }, this.accountEditDelay);
        this.closeModal();
      }
    },
    async goToNewCasePage(res, caseIdToTransfer) {
      //reset data
      this.$store.commit('SET_ACCOUNT_DATA', {});
      this.$store.commit('SET_CASES_DATA', []);
      this.$store.commit('SET_NEWLY_ADDED_CASE_ID', caseIdToTransfer);
      this.$router.push(`/account/${res.destinationUUID}/${caseIdToTransfer}`);
      this.$store.commit('SET_SNACKBAR', { open: false });
    }
  }
};
</script>