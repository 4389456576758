<template>
  <div>
    <v-card class="elevation-1 bg-whitesmoke mb-8 test-kit-card">
      <!-- APPBAR -->
      <v-app-bar class="elevation-0 bg-whitesmoke app-bar align-center">
        <v-toolbar-title class="primary--text text-uppercase subtitle-1 font-weight-medium">
          <div class="d-flex align-center">
            <div class="icon-border-custom border-primary me-2" style="border-color: var(--v-primary-base)">
              <v-icon color="primary" left>mdi-test-tube</v-icon>
            </div>
            Test Kit Info
          </div>
        </v-toolbar-title>
      </v-app-bar>

      <!-- TESTKIT CARDS -->
      <div class="white pa-4">
        <div v-if="testKitData.length == 0 && !dataLoaded">
          <v-skeleton-loader
            v-for="(page, i) in 3"
            :key="'skeletonloader-' + i"
            type="list-item"
            ref="skeleton"
            class="mx-auto my-0"
          ></v-skeleton-loader>
        </div>
        <div v-else-if="testKitData.length > 0 && dataLoaded">
          <v-card class="mx-auto mb-4" v-for="(kit, i) in testKitData" :key="'testkit-number-' + kit.number" outlined>
            <div class="d-flex">
              <v-card-text class="pb-4">
                <div class="overline">Kit Number:</div>
                <div class="subtitle-1 text--primary font-weight-medium" v-if="kit.number">{{ kit.number }}</div>
                <div class="subtitle-1 text--primary" v-if="kit.sampleResult">Sample Result: {{ kit.sampleResult }}</div>
                <div v-if="kit.sampleResultDate">Sample Result Date: {{ formatDate(kit.sampleResultDate) }}</div>
                <div v-if="kit.registerDate">Register Date: {{ formatDate(kit.registerDate) }}</div>
                <div>Payment Method: {{ displayPaymentMethod(kit.tags) }}</div>
                <div v-if="kit.linkedBy">Linked by: {{ kit.linkedBy }}</div>
              </v-card-text>
              <v-spacer></v-spacer>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="mt-1 pa-5" icon @click="printLinkedSubjectInfo(kit.number)">
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                </template>
                <span>Print</span>
              </v-tooltip>

              <v-menu bottom left offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" fab class="subject-menu-btn mt-1 me-2" small :loading="kit.loading">
                    <v-icon class="mx-auto">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="deleteTestkitDialog(kit.id)" :disabled="kit.loading || !hasDeletePermission">
                    <v-list-content>
                      <v-list-item-title> <v-icon left>mdi-delete</v-icon>Delete TestKit</v-list-item-title>
                      <v-list-item-subtitle v-if="!hasDeletePermission" class="caption ms-8 mt-0">
                        Can only delete if test kit has no result and user has permission
                      </v-list-item-subtitle>
                    </v-list-content>
                  </v-list-item>
                  <v-list-item @click="downloadTestkitCertificate(kit.number, i)" :disabled="kit.loading || !kit.sampleResult">
                    <v-list-content>
                      <v-list-item-title> <v-icon left>mdi-download</v-icon>Download Certificate</v-list-item-title>
                      <v-list-item-subtitle v-if="!kit.sampleResult" class="caption ms-8 mt-0">
                        Certificate available if test kit has result
                      </v-list-item-subtitle>
                    </v-list-content>
                  </v-list-item>
                  <v-list-item @click="editPaymentMethod(kit.tags, i)">
                    <v-list-content>
                      <v-list-item-title> <v-icon left>mdi-cash-multiple</v-icon>Edit Payment Method</v-list-item-title>
                      <v-list-item-subtitle v-if="!kit.sampleResult" class="caption ms-8 mt-0">
                        Edit payment method available if test kit has result
                      </v-list-item-subtitle>
                    </v-list-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-card>
        </div>
        <div v-else class="no-data">No Data</div>
      </div>
    </v-card>

    <!-- print component -->
    <PrintLinkedSubject v-if="linkedUser" id="printLinkedSubjectAuto" :linkedUser="linkedUser" :lastScannedBarcode="lastScannedBarcode" />

    <!-- DELETE DIALOG -->
    <v-dialog v-model="deleteDialogOpen" max-width="290" persistent>
      <v-card>
        <v-card-title class="headline"> Are you sure? </v-card-title>
        <v-card-text> Test kit data will be deleted from this subject. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteDialogOpen = false">Cancel</v-btn>
          <v-btn color="error" @click="deleteTestKit()" :loading="deletingTestKit" :disabled="deletingTestKit">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- payment method modal -->
    <PaymentMethodModal
      v-if="showPaymentMethodModal"
      :showPaymentMethodModal="showPaymentMethodModal"
      :paymentMethodParent="selectedPaymentMethod"
      @submitPaymentMethodParent="submitPaymentMethod"
    />
  </div>
</template>

<script>
import PrintLinkedSubject from '@/views/VisitorScanPage/components/PrintLinkedSubject.vue';
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import PaymentMethodModal from '@/views/VisitorScanPage/components/PaymentMethodModal.vue';
import { mapGetters } from 'vuex';

export default {
  mixins: [SharedFunctions],
  props: [],
  components: {
    PrintLinkedSubject,
    PaymentMethodModal
  },
  computed: {
    ...mapGetters(['caseSelectedData']),
    currentCaseId() {
      return this.caseSelectedData.id;
    },
    testKitData() {
      return this.$store.state.selectedCaseTestKitData;
    },
    hasDeletePermission() {
      if (this.userHasRole('service-center-admin')) return true;
      else return false;
    }
  },
  data() {
    return {
      dataLoaded: false,
      linkedUser: null,
      lastScannedBarcode: null,
      deleteDialogOpen: false,
      selectedIdForDelete: null,
      deletingTestKit: false,
      showPaymentMethodModal: false,
      selectedPaymentMethod: null,
      selectedIndex: null
    };
  },
  watch: {
    currentCaseId(n, o) {
      if (n && n != o) {
        this.getTestKitInfo();
      }
    }
  },
  created() {},
  methods: {
    getTestKitInfo() {
      this.dataLoaded = false;
      this.$store.dispatch('getPatientTestKitInfo', this.currentCaseId).finally(() => {
        this.dataLoaded = true;
      });
    },
    downloadTestkitCertificate(testKitNumber, i) {
      const payload = {
        caseId: this.currentCaseId,
        teskKitNumber: testKitNumber
      };
      this.$store.commit('SET_TESTKIT_RESULT_DOWNLOAD_LOADING', { index: i, action: true });
      this.$store.dispatch('downloadTestkitCertificate', payload).then(() => {
        this.$store.commit('SET_TESTKIT_RESULT_DOWNLOAD_LOADING', { index: i, action: false });
      });
    },
    printLinkedSubjectInfo(lastScannedBarcode) {
      this.linkedUser = this.buildLinkedUserData();
      this.lastScannedBarcode = lastScannedBarcode;
      setTimeout(() => {
        this.$htmlToPaper('printLinkedSubjectAuto', null);
      }, 400);
    },
    buildLinkedUserData() {
      const data = this.caseSelectedData;

      return {
        edCardId: data.edCardId ? data.edCardId : null,
        firstname: data.firstname ? data.firstname : null,
        lastname: data.lastname ? data.lastname : null,
        birthDay: data.birthDate ? data.birthDate : null,
        email: data.email ? data.email : null,
        gender: data.gender ? data.gender : null,
        accommodationAddress: data.address ? data.address : null,
        phonenumber: data.phoneNumber ? data.phoneNumber : null,
        azvNumber: data.azvCardNumber ? data.azvCardNumber : null,
        doctorEntry: {
          name: data.doctorEntry && data.doctorEntry.name ? data.doctorEntry.name : null
        }
      };
    },
    deleteTestkitDialog(id) {
      this.selectedIdForDelete = id;
      this.deleteDialogOpen = true;
    },
    deleteTestKit() {
      this.deletingTestKit = true;
      this.$store.dispatch('deleteTestkit', this.selectedIdForDelete).then(() => {
        this.deletingTestKit = false;
        this.deleteDialogOpen = false;
        this.getTestKitInfo();
      });
    },
    displayPaymentMethod(tags) {
      const paymentMethod = this.getPaymentMethod(tags);
      if (paymentMethod) {
        return paymentMethod;
      } else {
        return 'None';
      }
    },
    editPaymentMethod(tags, index) {
      this.selectedIndex = index;
      this.selectedPaymentMethod = this.getPaymentMethod(tags);
      this.showPaymentMethodModal = true;
    },
    getPaymentMethod(tags) {
      if (!tags) return false;
      const tagsArr = JSON.parse(tags);
      if (tagsArr && tagsArr.paymentMethod) return tagsArr.paymentMethod;
      else return false;
    },
    async submitPaymentMethod(value) {
      let testKitData = JSON.parse(JSON.stringify(this.testKitData[this.selectedIndex]));
      let tags = JSON.parse(testKitData.tags);
      tags.paymentMethod = value;
      testKitData.tags = JSON.stringify(tags);
      try {
        this.$store.commit('SET_SHOW_LOADER', {
          action: true,
          text: 'One momeent...'
        });
        await this.$store.dispatch('updatePatientTestKitInfo', testKitData);
        await this.$store.dispatch('getPatientTestKitInfo', this.currentCaseId).then(() => (this.dataLoaded = true));
      } finally {
        this.$store.commit('SET_SHOW_LOADER', {
          action: false,
          text: null
        });
      }
    }
  }
};
</script>

<style lang="scss">
.test-kit-card {
  .title {
    align-items: center;
    .icon-wrapper {
      border: 1px solid #366baf;
      padding: 5px;
      width: 40px;
      height: 40px;
      align-items: center;
      border-radius: 50px;
      display: flex;
      i {
        align-self: center;
        margin: 0 auto;
      }
      //display: flex;
    }
  }
  .no-data {
    padding: 20px;
    text-align: center;
  }
}
</style>
