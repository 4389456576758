import { render, staticRenderFns } from "./CaseSelectedFormLoader.vue?vue&type=template&id=193ac6be&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCol,VRow,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('193ac6be')) {
      api.createRecord('193ac6be', component.options)
    } else {
      api.reload('193ac6be', component.options)
    }
    module.hot.accept("./CaseSelectedFormLoader.vue?vue&type=template&id=193ac6be&", function () {
      api.rerender('193ac6be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/AccountPage/components/CaseSelectedFormLoader.vue"
export default component.exports