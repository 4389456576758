<template>
  <v-card class="elevation-0">
    <v-form v-model="formValid" ref="form-selected-subject-case" class="form-selected-subject-case">
      <transition name="slide-bottom" class="mt-0 pt-0">
        <v-alert prominent type="info" v-if="showEditAlert && !newCase" class="mb-4">
          <v-row align="center">
            <v-col class="grow">Changes have been made. Do you want to save them?</v-col>
            <v-col class="shrink">
              <v-btn
                color="white"
                class="grey--text text--darken-2 elevation-0"
                @click="saveCaseChanges()"
                :disabled="checkingAzvNumber || savingCaseChanges"
                :loading="checkingAzvNumber || savingCaseChanges"
                >Save Changes</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>
      </transition>
      <v-alert type="error" v-if="formError" dense dismissible class="mt-0"> Please fill in all required fields </v-alert>
      <v-container style="padding-top: 0px">
        <!-- state history -->
        <StateHistory v-if="!newCase" />

        <!-- title admin -->
        <template v-if="userHasRole('admin')">
          <v-row>
            <v-col class="filter-col" cols="12" md="10" offset-md="1">
              <label class="primary--text title">Administration</label>
              <v-divider class="title-border"></v-divider>
            </v-col>
          </v-row>

          <!-- select worker -->
          <v-row>
            <v-col class="filter-col" cols="12" md="10" offset-md="1">
              <v-select
                :items="assignedWorkerSelectionList"
                label="Case assigned to"
                v-model="formData.caseWorker"
                :loading="$store.state.caseAssignedWorkers.length == 0"
              ></v-select>
            </v-col>
          </v-row>
        </template>

        <!-- title personal info -->
        <v-row>
          <v-col class="filter-col" cols="12" md="10" offset-md="1">
            <label class="primary--text title"> Personal Info: </label>

            <v-divider class="title-border"></v-divider>
            <div class="edCardId-display ps-0 mt-1" v-if="!newCase && caseSelectedData.edCardId">
              <span class="label">ED card ID:</span> <span class="primary--text">{{ caseSelectedData.edCardId }}</span>
            </div>
          </v-col>
        </v-row>

        <!-- name/last name -->
        <v-row>
          <v-col class="filter-col" cols="12" md="5" offset-md="1">
            <v-text-field
              v-model="formData.firstname"
              :rules="[(v) => !!v || 'This field is required']"
              class="required"
              label="First name"
              required
            ></v-text-field>
          </v-col>
          <v-col class="filter-col" cols="12" md="5">
            <v-text-field
              v-model="formData.lastname"
              :rules="[(v) => !!v || 'This field is required']"
              class="required"
              label="Last name"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- gender / persoons number -->
        <v-row>
          <v-col cols="12" md="5" offset-md="1" class="filter-col">
            <v-select v-model="formData.gender" :items="genders" label="Gender"></v-select>
          </v-col>
          <v-col class="filter-col" cols="12" md="5">
            <v-text-field v-model="formData.idNumber" label="ID Number"></v-text-field>
          </v-col>
        </v-row>

        <!-- azv number / doctor -->
        <v-row>
          <v-col class="filter-col" cols="12" md="5" offset-md="1">
            <v-text-field
              v-model="formData.azvCardNumber"
              label="AZV Number"
              :loading="checkingAzvNumber"
              :disabled="checkingAzvNumber"
              @input="validateAzvNumberFunctionLocal($event)"
              type="text"
              :error-messages="errorAzvAreadyInUse"
              :rules="[(v) => validateAzvNumberLocal || !v || 'AZV number not valid']"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-autocomplete
              clearable
              :disabled="doctorOptions.length == 0"
              :loading="doctorOptions.length == 0"
              v-model="formData.doctorEntry.id"
              data-lpignore="true"
              :items="doctorOptions"
              label="Doctor"
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <!-- birthday and country -->
        <v-row>
          <v-col class="filter-col" cols="12" md="5" offset-md="1">
            <BirthDayPicker keyName="birthDate" :valueFromParent="formData.birthDate" :required="false" @updateParentValue="updateValue" />
          </v-col>
          <v-col class="filter-col" cols="12" md="5">
            <AutoCompleteCountriesSelect :value="formData.birthCountry" label="Birth Country" keyName="birthCountry" :required="false" />
          </v-col>
        </v-row>

        <!-- address -->
        <v-row>
          <v-col class="filter-col" cols="12" md="5" offset-md="1">
            <v-text-field v-model="formData.address" label="Address" hint="eg. Seroe Blanco 29A "></v-text-field>
          </v-col>
          <v-col class="filter-col" cols="12" md="5">
            <DistrictSelect :required="false" keyName="addressCity" :valueFromParent="formData.addressCity" />
          </v-col>
        </v-row>

        <!-- phone -->
        <v-row>
          <v-col class="filter-col" cols="12" md="5" offset-md="1">
            <v-text-field v-model="formData.phoneNumber" label="Phone" hint="eg. 593 1234"></v-text-field>
          </v-col>
          <v-col class="filter-col" cols="12" md="5">
            <v-text-field
              v-model="formData.email"
              :rules="emailRules"
              label="Email"
              type="email"
              class="required"
              required
              hint="john.doe@mail.com"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- title quarantine -->
        <v-row>
          <v-col class="filter-col" cols="12" md="10" offset-md="1">
            <label class="primary--text title">Quarantine Info</label>
            <v-divider class="title-border"></v-divider>
          </v-col>
        </v-row>

        <!-- search cases -->
        <v-row v-if="!withParentLink">
          <v-col class="filter-col" cols="12" md="10" offset-md="1">
            <SearchCases @searchCaseSelected="searchCaseSelected" :dataFromParent="formData.parentCase" />
          </v-col>
        </v-row>

        <!-- symptom list -->
        <v-row>
          <v-col class="filter-col" cols="12" md="10" offset-md="1">
            <v-select
              v-model="symptomsSelected"
              @change="updateSymtomsList()"
              :items="symptomsListSelection"
              label="Symptoms"
              small-chips
              deletable-chips
              multiple
              hint="Select all symptoms that apply"
              persistent-hint
            ></v-select>
          </v-col>
        </v-row>

        <!-- share data with -->
        <v-row>
          <v-col class="filter-col" cols="12" md="10" offset-md="1">
            <v-select
              v-model="shareDataSelected"
              @change="updateShareDataList()"
              :items="shareDataListSelection"
              label="Share Data with"
              small-chips
              deletable-chips
              multiple
              hint="Select all that apply"
              persistent-hint
            ></v-select>
          </v-col>
        </v-row>

        <!-- state & test done -->
        <v-row>
          <v-col class="filter-col" cols="12" md="5" offset-md="1">
            <v-select class="required" :items="states" :rules="[(v) => !!v || 'This field is required']" label="State" v-model="formData.state">
            </v-select>
          </v-col>
          <v-col class="filter-col" cols="12" md="5">
            <v-select :items="yesNo" label="Test Done" v-model="formData.testDone"></v-select>
          </v-col>
        </v-row>

        <!-- reachable / access to food -->
        <v-row>
          <v-col class="filter-col" cols="12" md="5" offset-md="1">
            <v-select :items="yesNo" label="Reachable" v-model="formData.reacheable"></v-select>
          </v-col>
          <v-col class="filter-col" cols="12" md="5">
            <v-select :items="yesNo" label="Access To Food" v-model="formData.accessToFood"></v-select>
          </v-col>
        </v-row>

        <!-- complying / risico group   -->
        <v-row>
          <v-col class="filter-col" cols="12" md="5" offset-md="1">
            <v-select :items="yesNo" label="Is Complying with Quarantine" v-model="formData.complyingWithQuarantine"></v-select>
          </v-col>
          <v-col class="filter-col" cols="12" md="5">
            <v-select :items="risicoGroup" label="Risico Group" v-model="formData.risicoGroup"></v-select>
          </v-col>
        </v-row>

        <!-- travel date / date out of quarantine -->
        <v-row>
          <v-col class="filter-col" cols="12" md="5" offset-md="1">
            <DatePicker
              :valueFromParent="formData.intoQuarantineDate"
              label="First Day of Quaratine"
              keyName="intoQuarantineDate"
              @updateParentValue="updateValue"
            />
          </v-col>
          <v-col class="filter-col" cols="12" md="5">
            <DatePicker
              :valueFromParent="formData.outOfQuarantineDate"
              label="Out of Quarantine Date"
              keyName="outOfQuarantineDate"
              :required="false"
              @updateParentValue="updateValue"
            />
          </v-col>
        </v-row>

        <!-- house size -->
        <v-row>
          <v-col class="filter-col" cols="12" md="5" offset-md="1">
            <v-select :items="twenytCountArray" label="House Size" v-model="formData.houseSize"></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="filter-col" cols="12" md="5" offset-md="1"> </v-col>
        </v-row>

        <!-- title comment -->
        <v-row>
          <v-col class="filter-col" cols="12" md="10" offset-md="1">
            <label class="primary--text title">Comment</label>
            <v-divider class="title-border"></v-divider>
          </v-col>
        </v-row>
        <!-- comment  -->
        <v-row>
          <v-col class="filter-col" cols="12" md="10" offset-md="1">
            <v-textarea outlined :counter="maxCommentChars" rows="3" label="Comment" :rules="rulesComment" v-model="formData.comment"></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import AutoCompleteCountriesSelect from '@/components/form-components/autocomplete-countries-select';
import BirthDayPicker from '@/components/form-components/birthday-picker.vue';
import DatePicker from '@/components/form-components/date-picker.vue';
import quarantineStatesJson from '@/store/data/quarantine-states.json';
import risicoGroupJson from '@/store/data/risico-groups.json';
import DistrictSelect from '@/components/form-components/autocomplete-districts-select.vue';
import SearchCases from '@/components/form-components/autocomplete-search-cases.vue';
import StateHistory from '@/components/account-and-case-components/state-history.vue';
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import gendersJson from '@/store/data/genders.json';
import { twentyCountArray } from '@/utils/counts.js';

export default {
  mixins: [SharedFunctions],
  props: ['formDataParent', 'newCase', 'withParentLink'],
  components: {
    AutoCompleteCountriesSelect,
    BirthDayPicker,
    DatePicker,
    DistrictSelect,
    SearchCases,
    StateHistory
  },
  computed: {
    ...mapGetters(['accountData', 'caseSelectedData', 'accountEditDelay']),
    formDataString() {
      return JSON.stringify(this.formData);
    },
    assignedWorkerSelectionList() {
      let caseAssignedWorkers = this.$store.state.caseAssignedWorkers.map((worker) => {
        return {
          value: worker.id,
          text: worker.firstName + ' ' + worker.lastName
        };
      });
      return caseAssignedWorkers;
    },
    parentCaseSelected() {
      return this.formData.parentCase;
    },
    doctorOptions() {
      return this.$store.state.doctorOptions;
    }
  },
  watch: {
    formDataString: {
      handler(n, o) {
        let oldObject = JSON.parse(o);
        let oldObjectCopy = o;
        oldObjectCopy = JSON.parse(oldObjectCopy);
        delete oldObjectCopy.vaccine;
        oldObjectCopy = JSON.stringify(oldObjectCopy);

        let newObjectCopy = n;
        newObjectCopy = JSON.parse(newObjectCopy);
        delete newObjectCopy.vaccine;
        newObjectCopy = JSON.stringify(newObjectCopy);

        //to avoid alert to trigger on tab change
        if (oldObject.firstname != null && newObjectCopy != oldObjectCopy && !this.newCase) this.showEditAlert = true;
      },
      deep: true
    },
    symptomsSelected(n, o) {
      if (this.dataLoaded && n && n != o) {
        this.showEditAlert = true;
      }
    },
    shareDataSelected(n, o) {
      if (this.dataLoaded && n && n != o) {
        this.showEditAlert = true;
      }
    },
    parentCaseSelected(n, o) {
      if (this.dataLoaded && n && n != o) {
        this.showEditAlert = true;
      }
    }
  },
  data() {
    return {
      dataLoaded: false,
      formError: false,
      showEditAlert: false,
      formValid: null,
      validateAzvNumberLocal: true,
      azvNumberCache: null,
      checkingAzvNumber: false,
      savingCaseChanges: false,
      errorAzvAreadyInUse: null,
      states: [],
      symptomsListSelection: [
        'No symptoms',
        'Diarrhea',
        'Fatigue',
        'Fever',
        'Headache',
        'Nausea',
        'Runny nose',
        'Shortness of breath',
        'Sore throat',
        'Vomiting',
        'Coughing'
      ],
      shareDataListSelection: [
        'Department of Public Health ',
        'Huisarts',
        'Apotheek/Botica',
        'Dr. Horacio E. Oduber Hospitaal (HOH)',
        'Instituto Medico San Nicolas (IMSaN)',
        'Sociale verzekeringsbank (SVB)',
        'Medwork Aruba'
      ],
      assignedWorkerSelection: [],
      yesNo: [
        { value: null, text: 'Select' },
        { value: '1', text: 'Yes' },
        { value: '0', text: 'No' }
      ],
      symptomsSelected: null,
      shareDataSelected: null,
      maxCommentChars: 250,
      rulesComment: [(v) => v == '' || v == null || (v && v.length <= this.maxCommentChars) || `Max ${this.maxCommentChars} characters`],
      //rulesComment: [v => v.length <= this.maxCommentChars || `Max ${this.maxCommentChars} characters`, v => !!v || 'This field is required'],
      //rulesAddress: [v => !!v || 'This field is required', v => /^[A-Za-z0-9]+[ ]?[A-Za-z0-9]*$/.test(v) || 'Please do not add a space between house number and number letter'],
      emailRules: [(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid', (v) => this.checkForDotEmail(v)],
      rulesAddress: [(v) => !!v || 'This field is required'],
      risicoGroup: risicoGroupJson,
      twenytCountArray: twentyCountArray(),
      formData: {
        userId: null,
        keycloakId: null,
        name: 'Case ' + Math.floor(1000 + Math.random() * 9000),
        firstname: null,
        lastname: null,
        gender: null,
        genders: [],
        address: null,
        addressCity: null,
        travelFrom: null,
        birthDate: null,
        birthCountry: null,
        comment: '',
        intoQuarantineDate: null,
        reacheable: null,
        state: null,
        symptomsList: null,
        accessToFood: null,
        travelSize: null,
        houseSize: null,
        risicoGroup: null,
        azvCardNumber: null,
        idNumber: null,
        testDone: null,
        complyingWithQuarantine: null,
        shareData: null,
        outOfQuarantineDate: null,
        phoneNumber: null,
        caseWorker: null,
        parentCase: null,
        email: null,
        doctorEntry: {
          id: null
        },
        //edCardId: null,
        countryResidency: null
        //stateResidency: null,
        //travelDeparture: null,
        //travelFlightNumber: null,
        //travelOrigin: null,
      }
    };
  },
  created() {
    //get doctors
    if (this.doctorOptions.length == 0) this.$store.dispatch('getDoctorOptions');
    this.genders = gendersJson;
    this.states = quarantineStatesJson;
    this.loadData();
    if (this.userHasRole('admin')) this.$store.dispatch('getCaseAssignedWorker');
  },
  methods: {
    loadData() {
      if (this.formDataParent) {
        this.formData = this.prepareParentData(this.formDataParent);
        if (this.formData.symptomsList) this.prefillSymtoms(this.formData.symptomsList);
        if (this.formData.shareData) this.prefillShareData(this.formData.shareData);
        //cache azv number for edit validation
        if (this.formData.azvCardNumber) this.azvNumberCache = this.formData.azvCardNumber;

        setTimeout(() => {
          this.dataLoaded = true;
        }, 0);
      } else {
        this.formData.userId = this.accountData.keycloakEmail;
        this.formData.keycloakId = this.accountData.keycloakId;
        this.dataLoaded = true;
      }

      if (this.formData.caseWorker == null) this.$set(this.formData, 'caseWorker', this.$store.state.userObject.sub);
      if (this.newCase) this.prefillOriginAndEdCard();
    },
    updateValue(value, keyName) {
      if (this.formData[keyName] === undefined) {
        this.$set(this.formData, keyName, null);
      }
      this.formData[keyName] = value;
    },
    updateSymtomsList() {
      this.formData.symptomsList = this.symptomsSelected.join();
    },
    updateShareDataList() {
      this.formData.shareData = this.shareDataSelected.join();
    },
    prefillSymtoms(symptoms) {
      this.symptomsSelected = symptoms.split(',');
    },
    prefillShareData(shareDataWith) {
      this.shareDataSelected = shareDataWith.split(',');
    },
    saveCaseChanges() {
      const dataToPost = this.cleanUpData(this.formData);
      this.formError = false;
      if (this.$refs['form-selected-subject-case'].validate() && !this.errorAzvAreadyInUse) {
        this.savingCaseChanges = true;
        this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'Editing case....' });
        this.$store
          .dispatch('updateCase', dataToPost)
          .then(() => {
            this.showEditAlert = false;
            this.azvNumberCache = this.formData.azvCardNumber;
            this.$store.dispatch('getStateHistory', this.caseSelectedData.id);
            this.$store.commit('SET_ACCOUNT_UPDATED', true);
          })
          .catch(() => {
            this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
          })
          .finally(() => {
            setTimeout(() => {
              this.savingCaseChanges = false;
              this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
            }, this.accountEditDelay);
          });
      } else {
        this.formError = true;
        this.clearFilter();
      }
    },
    searchCaseSelected(selectedCase) {
      let selectedCaseString = selectedCase.join(',');
      this.$set(this.formData, 'parentCase', selectedCaseString);
    },
    clearFilter() {
      const columns = document.querySelectorAll('.filter-col');
      for (var i = 0; i < columns.length; i++) {
        columns[i].classList.remove('hide');
      }
    },
    validateAzvNumberFunctionLocal(value) {
      if (this.validateAzvNumber(value)) {
        this.validateAzvNumberLocal = true;
        this.checkAzvExists(value);
      } else if (value == '') {
        this.validateAzvNumberLocal = true;
        this.errorAzvAreadyInUse = null;
      } else this.validateAzvNumberLocal = false;
    },
    checkAzvExists(value) {
      if (this.azvNumberCache != value) {
        this.checkingAzvNumber = true;
        if (this.newCase) this.$parent.$parent.$parent.$parent.checkingAzvNumber = true;
        this.azvNumberAlreadyRegistered(value)
          .then((result) => {
            if (result) {
              this.errorAzvAreadyInUse = 'AZV number already in use';
            } else this.errorAzvAreadyInUse = null;
          })
          .finally(() => {
            this.checkingAzvNumber = false;
            if (this.newCase) this.$parent.$parent.$parent.$parent.checkingAzvNumber = false;
          });
      } else this.errorAzvAreadyInUse = null;
    },
    prefillOriginAndEdCard() {
      this.formData.edCardId = 'PAPER_SELF_REGISTER_LOCAL';
      this.formData.countryResidency = 'ARUBA_LOCAL';
    },
    prepareParentData(parentData) {
      parentData = JSON.parse(JSON.stringify(parentData));
      if (!parentData.doctorEntry) {
        parentData.doctorEntry = {
          id: null
        };
      }
      return parentData;
    },
    cleanUpData(data) {
      data = JSON.parse(JSON.stringify(data));
      if (data.vaccine) delete data.vaccine;
      if (!data.doctorEntry?.id) delete data.doctorEntry;

      return data;
    }
  }
};
</script>

<style lang="scss">
.form-selected-subject-case {
  .title-border {
    border: 1px solid #366baf !important;
  }
  .edCardId-display {
    font-size: 14px;
    // margin-bottom: 3px;
    // //background: whitesmoke;
    // color: grey;
    // padding: 2px 10px;
    .label {
      color: grey;
    }
    .value {
    }
  }
}
</style>
