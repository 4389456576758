<template>
  <v-dialog v-model="showVaccineRegistrationDialog" persistent max-width="1200" scrollable>
    <v-card>
      <v-card-title class="headline primary white--text" error-title>
        Manual vaccination registration
        <v-spacer />
        <v-btn color="white mt-0" fab text small @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-4">
        <!-- ----------- -->
        <v-row>
          <v-col cols="12" md="6">
            <VaccineRegistrationCard
              :vaccineLocations="vaccineLocations"
              vaccineNumber="1"
              :hasFirstDosage="hasFirstDosage"
              v-on:vaccineActivated="vaccineActivated"
              :canActivate="true"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="vaccine1Active || hasFirstDosage">
            <VaccineRegistrationCard
              :vaccineLocations="vaccineLocations"
              vaccineNumber="2"
              :hasSecondDosage="hasSecondDosage"
              v-on:vaccineActivated="vaccineActivated"
              :canActivate="vaccine1ActiveState"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="vaccine2Active || hasSecondDosage">
            <VaccineRegistrationCard
              :vaccineLocations="vaccineLocations"
              vaccineNumber="3"
              :hasThirdDosage="hasThirdDosage"
              v-on:vaccineActivated="vaccineActivated"
              :canActivate="vaccine2ActiveStateState"
            />
          </v-col>

          <v-col cols="12" md="6" v-if="vaccine3Active || hasThirdDosage">
            <VaccineRegistrationCard
              :vaccineLocations="vaccineLocations"
              vaccineNumber="4"
              :hasFourthDosage="hasFourthDosage"
              v-on:vaccineActivated="vaccineActivated"
              :canActivate="vaccine3ActiveState"
            />
          </v-col>

           <v-col cols="12" md="6" v-if="vaccine4Active || hasFourthDosage">
            <VaccineRegistrationCard
              :vaccineLocations="vaccineLocations"
              vaccineNumber="5"
              :hasFourthDosage="hasFifthDosage"
              v-on:vaccineActivated="vaccineActivated"
              :canActivate="vaccine4ActiveState"
            />
          </v-col>

         <v-col cols="12" md="6" v-if="vaccine5Active || hasFifthDosage">
            <VaccineRegistrationCard
              :vaccineLocations="vaccineLocations"
              vaccineNumber="6"
              :hasFifthDosage="hasSixthDosage"
              v-on:vaccineActivated="vaccineActivated"
              :canActivate="vaccine5ActiveState"
            />
          </v-col>

            <v-col cols="12" md="6" v-if="vaccine6Active || hasSixthDosage">
            <VaccineRegistrationCard
              :vaccineLocations="vaccineLocations"
              vaccineNumber="7"
              :hasSixthDosage="hasSeventhDosage"
              v-on:vaccineActivated="vaccineActivated"
              :canActivate="vaccine6ActiveState"
            />
          </v-col>

          <v-col cols="12" md="6" v-if="vaccine7Active || hasSeventhDosage">
            <VaccineRegistrationCard
              :vaccineLocations="vaccineLocations"
              vaccineNumber="8"
              :hasSeventhDosage="hasEighthDosage"
              v-on:vaccineActivated="vaccineActivated"
              :canActivate="vaccine7ActiveState"
            />
          </v-col> <!---->
        </v-row>
        <!-- ----------- -->
      </v-card-text>

      <v-divider></v-divider>

      <v-alert type="error" text v-if="formError" dense dismissible class="mt-4 mx-4"> Please fill in all required fields </v-alert>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog()" :disabled="registering"> Close </v-btn>
        <v-btn color="primary" large :loading="registering" :disabled="registering || readyToSubmit" @click="validate()"> Register </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VaccineRegistrationCard from '@/views/AccountPage/VaccineInfoBlock/components/VaccineRegistrationCard.vue';
import { EventBus } from '@/event-bus.js';
import { mapGetters } from 'vuex';
import SharedFunctions from '@/mixins/SharedFunctions.vue';

export default {
  mixins: [SharedFunctions],
  props: ['showVaccineRegistrationDialog', 'caseSelectedData'],
  components: {
    VaccineRegistrationCard
  },
  created() {
    if (this.vaccineLocations.length == 0) this.getVaccineLocations();
  },
  data() {
    return {
      registering: false,
      loadingLocations: false,
      formError: false,
      vaccineNrState: {},
      vaccine1ActiveState: false,
      vaccine2ActiveState: false,
      vaccine3ActiveState: false,
      vaccine4ActiveState: false,
      vaccine5ActiveState: false,
      vaccine6ActiveState: false,
      vaccine7ActiveState: false,
      vaccine8ActiveState: false,
    };
  },
  computed: {
    ...mapGetters(['caseSelectedData']),
    vaccineDataPatientCase() {
      return this.$store.state.vaccine.vaccineDataPatientCase;
    },
    vaccineLocations() {
      return this.$store.state.vaccine.locations.filter((location) => location.dossageAllow);
    },
    dialogDataStore() {
      return this.$store.getters.vaccineRegirationDialogData;
    },
    vaccine1Active() {
      return this.dialogDataStore.vaccine1Active;
    },
    vaccine2Active() {
      return this.dialogDataStore.vaccine2Active;
    },
    vaccine3Active() {
      return this.dialogDataStore.vaccine3Active;
    },
    vaccine4Active() {
      return this.dialogDataStore.vaccine4Active;
    },
    vaccine5Active() {
      return this.dialogDataStore.vaccine5Active;
    },
    vaccine6Active() {
      return this.dialogDataStore.vaccine6Active;
    },
    vaccine7Active() {
      return this.dialogDataStore.vaccine7Active;
    },
    vaccine8Active() {
      return this.dialogDataStore.vaccine8Active;
    },
    hasFirstDosage() {
      return this.vaccineDataPatientCase?.vaccine1Dose ? true : false;
    },
    hasSecondDosage() {
      return this.vaccineDataPatientCase?.vaccine2Dose ? true : false;
    },
    hasThirdDosage() {
      return this.vaccineDataPatientCase?.vaccine3Dose ? true : false;
    },
    hasFourthDosage() {
      return this.vaccineDataPatientCase?.vaccine4Dose ? true : false;
    },
    hasFifthDosage() {
      return this.vaccineDataPatientCase?.vaccine5Dose ? true : false;
    },
    hasSixthDosage() {
      return this.vaccineDataPatientCase?.vaccine6Dose ? true : false;
    },
    hasSeventhDosage() {
      return this.vaccineDataPatientCase?.vaccine7Dose ? true : false;
    },
    hasEighthDosage() {
      return this.vaccineDataPatientCase?.vaccine8Dose ? true : false;
    },
    readyToSubmit() {
      if (this.vaccine1Active || this.vaccine2Active || this.vaccine3Active || this.vaccine4Active
      || this.vaccine5Active || this.vaccine6Active || this.vaccine7Active || this.vaccine8Active) return false;
      else return true;
    }
  },
  methods: {
    closeDialog() {
      this.$parent.showVaccineRegistrationDialog = false;
    },
    getVaccineLocations() {
      this.loadingLocations = true;
      this.$store.dispatch('getVaccineLocations').finally(() => {
        this.loadingLocations = false;
      });
    },
    validate() {
      //event bus is dynamic with number concatenated
      if (this.dialogDataStore.vaccine1Active) EventBus.$emit('vaccine1RegistrationValidate');
      if (this.dialogDataStore.vaccine2Active) EventBus.$emit('vaccine2RegistrationValidate');
      if (this.dialogDataStore.vaccine3Active) EventBus.$emit('vaccine3RegistrationValidate');
      if (this.dialogDataStore.vaccine4Active) EventBus.$emit('vaccine4RegistrationValidate');
      if (this.dialogDataStore.vaccine5Active) EventBus.$emit('vaccine5RegistrationValidate');
      if (this.dialogDataStore.vaccine6Active) EventBus.$emit('vaccine6RegistrationValidate');
      if (this.dialogDataStore.vaccine7Active) EventBus.$emit('vaccine7RegistrationValidate');
      if (this.dialogDataStore.vaccine8Active) EventBus.$emit('vaccine8RegistrationValidate');

      if (
        (!this.dialogDataStore.form1Valid && this.dialogDataStore.vaccine1Active) ||
        (!this.dialogDataStore.form2Valid && this.dialogDataStore.vaccine2Active) ||
        (!this.dialogDataStore.form3Valid && this.dialogDataStore.vaccine3Active) ||
        (!this.dialogDataStore.form4Valid && this.dialogDataStore.vaccine4Active) ||
        (!this.dialogDataStore.form5Valid && this.dialogDataStore.vaccine5Active) ||
        (!this.dialogDataStore.form6Valid && this.dialogDataStore.vaccine6Active) ||
        (!this.dialogDataStore.form7Valid && this.dialogDataStore.vaccine7Active) ||
        (!this.dialogDataStore.form8Valid && this.dialogDataStore.vaccine8Active)
      ) {
        this.formError = true;
      } else {
        this.formError = false;
        this.register();
      }
    },
    vaccineActivated(data) {
      if (data.nr == 1) {
        this.vaccine1ActiveState = data.active;
        if(!data.active)
          this.vaccine2ActiveState = false;
      }
      if (data.nr == 2) {
        this.vaccine2ActiveState = data.active;
        if(!data.active)
          this.vaccine3ActiveState = false;
      }
      if (data.nr == 3) {
        this.vaccine3ActiveState = data.active;
        if(!data.active)
          this.vaccine4ActiveState = false;
      }
      if (data.nr == 4) {
        this.vaccine4ActiveState = data.active;
        if(!data.active)
          this.vaccine5ActiveState = false;
      }

      if (data.nr == 5) {
        this.vaccine5ActiveState = data.active;
        if(!data.active)
          this.vaccine6ActiveState = false;
      }

      if (data.nr == 6) {
        this.vaccine6ActiveState = data.active;
        if(!data.active)
          this.vaccine7ActiveState = false;
      }

      if (data.nr == 7) {
        this.vaccine7ActiveState = data.active;
        if(!data.active)
          this.vaccine8ActiveState = false;
      }

      if (data.nr == 8) {
        this.vaccine8ActiveState = data.active;
        if(!data.active)
          this.vaccine9ActiveState = false;
      }
    },
    async register() {
      this.register = true;
      let payload = {
        patientCase: {
          id: this.caseSelectedData.id
        },
        vaccineDate1: this.dialogDataStore.form1Data.date,
        vaccineDate2: this.dialogDataStore.form2Data.date,
        vaccineDate3: this.dialogDataStore.form3Data.date,
        vaccineDate4: this.dialogDataStore.form4Data.date,
        vaccineDate5: this.dialogDataStore.form5Data.date,
        vaccineDate6: this.dialogDataStore.form6Data.date,
        vaccineDate7: this.dialogDataStore.form7Data.date,
        vaccineDate8: this.dialogDataStore.form8Data.date,
        inventoryIdVaccine1: this.dialogDataStore.form1Data.inventoryIdVaccine,
        inventoryIdVaccine2: this.dialogDataStore.form2Data.inventoryIdVaccine,
        inventoryIdVaccine3: this.dialogDataStore.form3Data.inventoryIdVaccine,
        inventoryIdVaccine4: this.dialogDataStore.form4Data.inventoryIdVaccine,
        inventoryIdVaccine5: this.dialogDataStore.form5Data.inventoryIdVaccine,
        inventoryIdVaccine6: this.dialogDataStore.form6Data.inventoryIdVaccine,
        inventoryIdVaccine7: this.dialogDataStore.form7Data.inventoryIdVaccine,
        inventoryIdVaccine8: this.dialogDataStore.form8Data.inventoryIdVaccine,
        vaccine1Nurse: this.dialogDataStore.form1Data.nurseName,
        vaccine2Nurse: this.dialogDataStore.form2Data.nurseName,
        vaccine3Nurse: this.dialogDataStore.form3Data.nurseName,
        vaccine4Nurse: this.dialogDataStore.form4Data.nurseName,
        vaccine5Nurse: this.dialogDataStore.form5Data.nurseName,
        vaccine6Nurse: this.dialogDataStore.form6Data.nurseName,
        vaccine7Nurse: this.dialogDataStore.form7Data.nurseName,
        vaccine8Nurse: this.dialogDataStore.form8Data.nurseName,
        vaccineId: this.vaccineDataPatientCase ? this.vaccineDataPatientCase.id : null
      };

      payload = this.cleanUpSubmitData(payload);

      try {
        this.registering = true;
        const registrationCreated = await this.$store.dispatch('REGISTER_GHOST_VACCINATION', payload);
        if (registrationCreated) {
          this.$emit('initVaccineDataParent', this.caseSelectedData.id);
          this.closeDialog();
        }
      } finally {
        this.registering = false;
      }
    },
    cleanUpSubmitData(data) {
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key];
          if (element === null) delete data[key];
        }
      }
      return data;
    }
  }
};
</script>

<style>
</style>