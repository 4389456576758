<template>
  <div class="wrapper">
    <v-card class="elevation-1 bg-whitesmoke mb-8 vaccine-info test-kit-card" v-if="hasVaccineRoles || userHasGroup('DVG-AAA')">
      <!-- APP BAR -->
      <v-app-bar class="elevation-0 bg-whitesmoke app-bar align-center">
        <v-toolbar-title class="primary--text text-uppercase subtitle-1 font-weight-medium">
          <div class="d-flex align-center">
            <div class="icon-border-custom border-primary me-2" style="border-color: var(--v-primary-base)">
              <v-icon color="primary" left>mdi-needle</v-icon>
            </div>
            Vaccine Info
            <v-chip small class="ms-2 error--text red lighten-5"  v-if="vaccineIsSuspended">
              Is suspended
            </v-chip>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- MENU -->
        <v-menu bottom left offset-y v-if="hasVaccineRoles && userHasRole('admin')">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" fab class="subject-menu-btn">
              <v-icon large class="mx-auto">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <div v-tooltip="vaccineDataPatientCase ? 'Cannot create appointment if already has an appointment or has vaccine(s)' : ''">
              <v-list-item @click="openVaccineCalendarDialog()" :disabled="vaccineDataPatientCase || vaccineIsSuspended">
                <v-list-content>
                  <v-list-item-title> <v-icon :disabled="vaccineIsSuspended" left>mdi-calendar-clock</v-icon>Create Appointment </v-list-item-title>
                </v-list-content>
              </v-list-item>
            </div>
            
            <div v-tooltip="!enableRescheduleVaccineAppointment ? ' Can only reschedule if only first vaccine dose is given' : ''">
              <v-list-item @click="openVaccineCalendarDialog()" :disabled="!enableRescheduleVaccineAppointment || vaccineIsSuspended">
                <v-list-content>
                  <v-list-item-title> <v-icon :disabled="vaccineIsSuspended" left>mdi-calendar</v-icon>Reschedule 2nd appointment </v-list-item-title>
                </v-list-content>
              </v-list-item>
            </div>
            <div v-tooltip="disableRemoveApointment ? 'Can only remove if appointment pending' : ''">
              <v-list-item @click="dialogRemoveVaccine = true" :disabled="disableRemoveApointment || vaccineIsSuspended">
                <v-list-content>
                  <v-list-item-title> <v-icon :disabled="vaccineIsSuspended" left>mdi-delete</v-icon>Remove appointment </v-list-item-title>
                </v-list-content>
              </v-list-item>
            </div>

            <v-divider class="mx-4"></v-divider>

            <div v-tooltip="vaccineDataPatientCase || vaccineRequested ? 'Cannot request if already has request pending or has vaccine(s)' : ''">
              <v-list-item @click="showRequestVaccineModal = true" :disabled="vaccineDataPatientCase || vaccineRequested || vaccineIsSuspended">
                <v-list-content>
                  <v-list-item-title> <v-icon :disabled="vaccineIsSuspended" left>mdi-needle</v-icon>Request Vaccine</v-list-item-title>
                </v-list-content>
              </v-list-item>
            </div>
            <div v-tooltip="hasAllVaccines ? 'Already recieved all 4 dosages' : ''">
              <v-list-item @click="showVaccineRegistrationDialog = true" :disabled="hasAllVaccines || vaccineIsSuspended">
                <v-list-content>
                  <v-list-item-title>
                    <v-icon :disabled="vaccineIsSuspended" left>mdi-map-marker-check</v-icon>
                    Manual vaccination registration
                  </v-list-item-title>
                </v-list-content>
              </v-list-item>
            </div>
            <div v-tooltip="vaccineIsSuspended ? 'Unsuspend' : 'Suspend'">
              <v-list-item @click="toggleVaccineSuspension" :disabled="!hasVaccine">
                <v-list-content>
                  <v-list-item-title> 
                    <v-icon v-if="vaccineIsSuspended" left>mdi-lock-open-check</v-icon>
                    <v-icon v-else left>mdi-lock-minus</v-icon>{{ vaccineIsSuspended ? "Unsuspend" : "Suspend" }}</v-list-item-title>
                </v-list-content>
              </v-list-item>
            </div>
            <v-divider class="mx-4"></v-divider>
            <div v-tooltip="loadingVaccineCertificate || disableDownloadVaccineCertificate ? 'Can only download if vaccination valid' : ''">
              <v-list-item @click="downloadVaccineCertificate()" :disabled="loadingVaccineCertificate || disableDownloadVaccineCertificate || !hasVaccine">
                <v-list-content>
                  <v-list-item-title> <v-icon :disabled="vaccineIsSuspended" left>mdi-file-certificate</v-icon>Download Certificate</v-list-item-title>
                </v-list-content>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </v-app-bar>

      <!-- LOADER -->
      <div v-if="vaccineDataPatientCase == null && !vaccineDataLoaded" class="py-4 white">
        <v-skeleton-loader
          v-for="(page, i) in 3"
          :key="'skeletonloader-' + i"
          type="list-item"
          ref="skeleton"
          class="mx-auto my-0"
        ></v-skeleton-loader>
      </div>

      <!-- VACCINE DATA -->
      <div class="white pa-4" v-if="vaccineDataPatientCase && vaccineDataLoaded">
        <!-- appointment locations -->
        <v-alert dense text type="info" icon="mdi-map-marker" v-if="vaccineDataPatientCase.appointmentLocation">
          <div class="caption">Appointment location and addres:</div>
          <div>{{ vaccineDataPatientCase.appointmentLocation.name }}, {{ vaccineDataPatientCase.appointmentLocation.address }}</div>
        </v-alert>

        <v-dialog v-model="displayVaccineDataDialog" width="500">
          <v-card>
            <v-card-title class="headline primary white--text">
              Vaccine info
              <v-spacer />
              <v-btn color="white mt-0" fab text small @click="displayVaccineDataDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text class="mt-5">
              <DosageCard
                :dosage="selectedVaccineData.vaccineDose"
                :appointment="selectedVaccineData.appointment"
                :vaccineNumber="selectedVaccineData.vaccineNr"
                :vaccineDate="selectedVaccineData.vaccineDate"
                :isLastTaken="getLastDosageTaken(selectedVaccineData.vaccineNr)"
                :hasGroupDeleteDosagePermission="hasGroupDeleteDosagePermission"
                :hasRemoveRole="hasRemoveRole"
                v-on:refresh="refresh"
              />
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-simple-table v-if="vaccineData">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Number</th>
                <th class="text-left">Date</th>
                <th class="text-left">Taken</th>
              </tr>
            </thead>
            <tbody>
              <tr style="cursor: pointer" v-for="vaccine in vaccineData" :key="vaccine.vaccineGroupedName" @click="displayVaccineData(vaccine)">
                <td>{{ 'Dosage ' + vaccine.vaccineNr }}</td>
                <td>{{ vaccine.vaccineDate ? formatDateTime(vaccine.vaccineDate) : '' }}</td>
                <td>
                  <v-avatar class="avatar-wrapper pa-0" size="30" v-if="vaccine.vaccineDate">
                    <v-icon class="ma-0" color="green" large>mdi-check-circle</v-icon>
                  </v-avatar>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div v-else class="text-center full-width">No Data</div>
      </div>
      <div class="white pa-8 d-flex" v-else-if="vaccineDataLoaded">
        <v-chip color="warning" class="mx-auto" v-if="vaccineRequested">
          <v-icon left small> mdi-alert-circle </v-icon>
          Vaccine Requested
        </v-chip>
        <div v-else class="text-center full-width">No Data</div>
      </div>
    </v-card>
    <!-- REMOVE APPOINTMENT DIALOG -->
    <RemoveVaccineAppointment
      :dialogRemoveVaccine="dialogRemoveVaccine"
      v-if="dialogRemoveVaccine"
      :vaccineId="vaccineDataPatientCase.id"
      :caseSelectedData="caseSelectedData"
      @initVaccineDataParent="initVaccineData"
    />
    <!-- DIALOG VACCINE CALENDAR -->
    <VaccineCalendarDialog
      :dialogProp="vaccineCalendarDialog"
      :caseSelectedData="caseSelectedData"
      :vaccineDataPatientCase="vaccineDataPatientCase"
      v-if="vaccineCalendarDialog"
      @vaccineCalendarDialogCallback="vaccineCalendarDialogDone"
    />
    <!-- REQUEST VACCINE DIALOG -->
    <RequestVaccineModal
      v-if="showRequestVaccineModal"
      :showRequestVaccineModal="showRequestVaccineModal"
      :caseSelectedData="caseSelectedData"
      @initVaccineDataParent="initVaccineData"
    />
    <!-- VACCINE REGISTRATION DIALOG -->
    <VaccineRegistrationDialog
      v-if="showVaccineRegistrationDialog"
      :showVaccineRegistrationDialog="showVaccineRegistrationDialog"
      :caseSelectedData="caseSelectedData"
      @initVaccineDataParent="initVaccineData"
    />

    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DosageCard from '@/views/VaccineViews/components/DosageCard.vue';
import VaccineCalendarDialog from '@/views/VaccineViews/components/vaccine-calendar-dialog.vue';
import RequestVaccineModal from '@/views/AccountPage/components/RequestVaccineModal.vue';
import RemoveVaccineAppointment from '@/views/AccountPage/VaccineInfoBlock/components/remove-vaccine-appointment.vue';
import VaccineRegistrationDialog from '@/views/AccountPage/VaccineInfoBlock/components/VaccineRegistrationDialog.vue';
import SharedFunctions from '@/mixins/SharedFunctions.vue';

export default {
  props: [],
  mixins: [SharedFunctions],
  components: {
    DosageCard,
    RemoveVaccineAppointment,
    VaccineCalendarDialog,
    RequestVaccineModal,
    VaccineRegistrationDialog
  },
  data() {
    return {
      dialogRemoveVaccine: false,
      displayVaccineDataDialog: false,
      hasGroupDeleteDosagePermission: false,
      // hasVaccine: false,
      vaccineCalendarDialog: false,
      loadingVaccineCertificate: false,
      disableDownloadVaccineCertificate: true,
      showRequestVaccineModal: false,
      vaccineRequested: false,
      selectedVaccineData: {},
      showVaccineRegistrationDialog: false,
      vaccineDataLoaded: false,
      vaccineData: {},
      vaccineIsSuspended: false,
      vaccinePatientCase: null
    };
  },
  created() {
    this.hasGroupDeleteDosagePermission = this.userHasGroup('vaccine-delete-dosage');
  },
  computed: {
    ...mapGetters(['caseSelectedData']),
    hasVaccine() {
      return Object.keys(this.vaccineData).length > 0;
    },
    hasVaccineRoles() {
      if (this.userHasRole('vaccine-scan') || this.userHasRole('vaccine-registration') || this.userHasRole('vaccine-inventory')) {
        return true;
      } else return false;
    },
    hasRemoveRole() {
      return this.userHasRole('dose-remove');
    },
    vaccineDataPatientCase() {
      return this.$store.state.vaccine.vaccineDataPatientCase;
    },
    disableRemoveApointment() {
      if (this.vaccineDataPatientCase?.appointment1 && !this.vaccineDataPatientCase?.vaccine1) 
        return false;
      return true;
    },
    enableRescheduleVaccineAppointment() {
      if (this.vaccineDataPatientCase && this.vaccineDataPatientCase?.vaccine1Dose && !this.vaccineDataPatientCase?.vaccine2Dose) return true;
      else return false;
    },
    hasAllVaccines() {
      if (this.vaccineDataPatientCase?.vaccine1Dose && this.vaccineDataPatientCase?.vaccine2Dose && this.vaccineDataPatientCase?.vaccine3Dose 
      && this.vaccineDataPatientCase?.vaccine4Dose && this.vaccineDataPatientCase?.vaccine5Dose && this.vaccineDataPatientCase?.vaccine6Dose 
      && this.vaccineDataPatientCase?.vaccine7Dose && this.vaccineDataPatientCase?.vaccine8Dose)
        return true;
      return false;
    }
  },
  watch: {
    caseSelectedData(n, o) {
      if (n && n.id && n != o) {
        this.initVaccineData(n.id);
      }
    },
    vaccineDataLoaded(loaded) {
      if (loaded) 
        this.vaccineData = this.groupVaccineData(this.vaccineDataPatientCase);
    }
  },
  methods: {
    displayVaccineData(vaccine) {
      if(this.vaccineIsSuspended)
      {
        this.$store.dispatch("showError", "Vaccine data is suspended until further notice.");
        return;
      }
      this.displayVaccineDataDialog = true;
      this.selectedVaccineData = vaccine;
    },
    notNullOrEmpty(value) {
      return value !== null && value !== undefined;
    },
    getLastDosageTaken(dosageNr) {
      if (!dosageNr) return false;
      let dose1Taken = this.notNullOrEmpty(this.vaccineDataPatientCase.vaccine1Dose);
      let dose2Taken = this.notNullOrEmpty(this.vaccineDataPatientCase.vaccine2Dose);
      let dose3Taken = this.notNullOrEmpty(this.vaccineDataPatientCase.vaccine3Dose);
      let dose4Taken = this.notNullOrEmpty(this.vaccineDataPatientCase.vaccine4Dose);
      let dose5Taken = this.notNullOrEmpty(this.vaccineDataPatientCase.vaccine5Dose);
      let dose6Taken = this.notNullOrEmpty(this.vaccineDataPatientCase.vaccine6Dose);
      let dose7Taken = this.notNullOrEmpty(this.vaccineDataPatientCase.vaccine7Dose);
      let dose8Taken = this.notNullOrEmpty(this.vaccineDataPatientCase.vaccine8Dose);

      if (dosageNr == 1) return dose1Taken && !dose2Taken && !dose3Taken;
      if (dosageNr == 2) return dose2Taken && !dose3Taken;
      if (dosageNr == 3) return dose3Taken && !dose4Taken;
      if (dosageNr == 4) return dose4Taken && !dose5Taken;
      if (dosageNr == 5) return dose5Taken && !dose6Taken;
      if (dosageNr == 6) return dose6Taken && !dose7Taken;
      if (dosageNr == 7) return dose7Taken && !dose8Taken;
      if (dosageNr == 8) return dose8Taken;
    },
    async initVaccineData(id) {
      this.vaccineDataLoaded = false;
      this.vaccinePatientCase = await this.$store.dispatch('getVaccinePatientCase', id);
      this.vaccineIsSuspended = this.vaccinePatientCase.suspend;      
      //this.hasVaccine = this.vaccinePatientCase.vaccine !== undefined;

      this.vaccineDataLoaded = true;
      if (!this.vaccinePatientCase) {
        this.vaccineDataLoaded = false;
        await this.getVaccineRequestData(id);
        this.vaccineDataLoaded = true;
      } else {
        await this.checkForVaccineCertificate();
      }
    },
    openVaccineCalendarDialog() {
      this.vaccineCalendarDialog = true;
    },
    downloadVaccineCertificate() {
      const payload = {
        patientCaseId: this.caseSelectedData.id,
        vaccineId: this.vaccineDataPatientCase.id
      };

      this.$store.dispatch('downloadVaccineCertificate', payload);
    },
    async checkForVaccineCertificate() {
      this.loadingVaccineCertificate = true;

      await this.$store
        .dispatch('CHECK_FOR_VACCINE_CERTIFICATE', this.caseSelectedData.id)
        .then((data) => {
          if (data) {
            this.disableDownloadVaccineCertificate = false;
          } else {
            this.disableDownloadVaccineCertificate = true;
          }
        })
        .finally(() => {
          this.loadingVaccineCertificate = false;
        });
    },
    async getVaccineRequestData(id) {
      await this.$store.dispatch('GET_VACCINE_REQUEST', id).then((data) => {
        if (data && data.length > 0) {
          this.vaccineRequested = true;
        } else this.vaccineRequested = false;
      });
    },
    async refresh() {
      await this.$store.dispatch('getVaccinePatientCase', this.caseSelectedData.id);
      this.displayVaccineDataDialog = false;
      this.selectedVaccineData = {};
      this.vaccineData = this.groupVaccineData(this.vaccineDataPatientCase);
    },
    vaccineCalendarDialogDone() {
      this.initVaccineData(this.caseSelectedData.id);
      this.vaccineCalendarDialog = false;
    },
    async toggleVaccineSuspension()
    {
      try {
        this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'Executing suspension...' });
        await this.$store.dispatch("suspendVaccineDosage", { 
          "id" : this.vaccinePatientCase.id,
          "state" : !this.vaccinePatientCase.suspend
          });
  
        // Refresh data.
        this.vaccinePatientCase = await this.$store.dispatch('getVaccinePatientCase', this.caseSelectedData.id);
        this.vaccineIsSuspended = this.vaccinePatientCase.suspend;

        
      } catch (error) {
          this.$store.dispatch("showError", "Suspension faild.");        
      }
      finally{
        this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
      }
    }
  }
};
</script>

<style lang="scss">
.vaccine-info {
  .v-icon {
    color: lighten(grey, 20%);
    margin-right: 5px;
  }
}
</style>