<template>
  <div>
    <!-- APPBAR -->
    <v-app-bar class="elevation-0 bg-whitesmoke app-bar align-center">
      <template v-if="caseSelectedData.id">
        <v-toolbar-title>
          <div class="d-flex align-center">
            <div class="icon-border-custom border-primary me-2" style="border-color: var(--v-primary-base)">
              <v-icon color="primary" left>mdi-folder</v-icon>
            </div>
            <div class="subtitle-1 font-weight-medium" style="line-height: 1.3" v-if="caseSelectedData.name">
              <div class="primary--text">{{ caseSelectedData.name }} : {{ caseSelectedData.firstname }} {{ caseSelectedData.lastname }}</div>
              <v-chip x-small class="primary text-uppercase" v-if="subjectState">{{ subjectState }}</v-chip>
            </div>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- contact tracing button -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openSubjectFormDialog(true)"
              fab
              small
              text
              class="me-1"
              elevation="0"
              v-bind="attrs"
              v-on="on"
              color="warning"
              :disabled="cannotAddContactTracing"
            >
              <v-icon>mdi-folder-network</v-icon>
            </v-btn>
          </template>
          <span>Create contact tracing case</span>
        </v-tooltip>
        <!-- filter button -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small fab @click="formFilterDisplay()" class="ms-1" v-bind="attrs" v-on="on">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Search form fields</span>
        </v-tooltip>
        <!-- overflow menu -->
        <v-menu bottom left offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" fab>
              <v-icon large>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item color="primary">
              <v-list-item-subtitle> Registration </v-list-item-subtitle>
            </v-list-item>
            <v-list-item @click="goToIntakeForm()">
              <v-list-item-title><v-icon left>mdi-form-select</v-icon>Create/Edit intake form</v-list-item-title>
            </v-list-item>
            <v-list-item @click="goToVisitorScan()">
              <v-list-item-title><v-icon left>mdi-qrcode-scan</v-icon>Scan barcode</v-list-item-title>
            </v-list-item>
            <v-list-item @click="printRegistration()">
              <v-list-item-title><v-icon left>mdi-printer</v-icon>Print registration</v-list-item-title>
            </v-list-item>
            <v-list-item @click="copyPatientCase()">
              <v-list-item-title><v-icon left>mdi-content-copy</v-icon>Copy patientcase</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle> Vaccine </v-list-item-subtitle>
            </v-list-item>
            <v-list-item @click="goToVaccineCheckinScan()">
              <v-list-item-title><v-icon left>mdi-map-marker-plus</v-icon>Vaccine check in</v-list-item-title>
            </v-list-item>
            <v-list-item @click="goToVaccineScan()">
              <v-list-item-title><v-icon left>mdi-map-marker-minus</v-icon>Vaccine check out</v-list-item-title>
            </v-list-item>
            <v-list-item @click="printVaccineRegistration()">
              <v-list-item-title><v-icon left>mdi-printer</v-icon>Print vaccine registration</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle> General </v-list-item-subtitle>
            </v-list-item>
            <v-list-item @click="transferCaseDialog(true)">
              <v-list-item-title> <v-icon left>mdi-folder-move-outline</v-icon>Transfer case </v-list-item-title>
            </v-list-item>
            <v-list-item @click="openSubjectFormDialog(true)" :disabled="cannotAddContactTracing">
              <v-list-item-title> <v-icon left>mdi-folder-network</v-icon>Create contact tracing case </v-list-item-title>
            </v-list-item>
            <v-list-item @click="dialogConfirmDeletePatientCase = true" :disabled="cannotAddContactTracing">
              <v-list-content>
                <v-list-item-title><v-icon left>mdi-delete</v-icon>Delete case</v-list-item-title>
                <v-list-item-subtitle v-if="cannotAddContactTracing" class="caption ms-8 mt-0 error--text">
                  Cannot delete a contact tracing case
                </v-list-item-subtitle>
              </v-list-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>
    <div class="px-3">
      <v-row class="pt-1 row-header mb-0 white" style="position: relative">
        <!-- alert cannot contact trace -->
        <v-col cols="12" v-if="cannotAddContactTracing" class="pb-0">
          <v-alert dense color="error" text class="caption ma-0" icon="mdi-folder-network">
            <v-row align="center">
              <v-col class="grow">
                Cannot add contact tracing case directly to another contact tracing case. To do this, search {{ caseSelectedData.name }} and then add
                contact tracing case.
              </v-col>
              <v-col class="shrink">
                <v-btn color="error" outlined small @click="searchCase()">Search case </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>

        <!-- form filter -->
        <v-text-field
          v-if="showFormFilter"
          @input="filterFormElemts"
          clearable
          rounded
          autocomplete="off"
          color="primary"
          hide-details
          outlined
          dense
          ref="form-filter-input"
          placeholder="eg. name, phone, email, etc"
          @click:clear="clearFilter()"
          append-icon="mdi-filter-variant"
          class="ma-0 pa-0 mt-4 mx-4 full-width"
        />
      </v-row>

      <!-- print registration component -->
      <PrintSubject :formDataParent="formData" :patientCase="patientCase" id="printSubjectTable" />
      <!-- print vaccine registration component -->
      <PrintVaccineRegistration
        :formData="formData"
        :patientCase="patientCase"
        :noEmail="noEmail"
        :consentFormData="consentFormData"
        :id="'PrintVaccineRegistration'"
      />
      <!-- dialog confirm delete patiencase -->
      <ConfirmDeletePatientCaseDialog
        :dialogConfirmDeletePatientCase="dialogConfirmDeletePatientCase"
        :case="caseSelectedData"
        v-if="dialogConfirmDeletePatientCase"
      />
      <!-- dialog transfer patiencase -->
      <TransferCaseDialog :dialogTransferCase="dialogTransferCase" :selectedCase="caseSelectedData" v-if="dialogTransferCase" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PrintSubject from '@/views/VisitorScanPage/components/PrintSubject.vue';
import PrintVaccineRegistration from '@/views/VaccineViews/VaccineRegistrationPage/components/PrintVaccineRegistration.vue';
import ConfirmDeletePatientCaseDialog from '@/views/AccountPage/components/ConfirmDeletePatientCaseDialog.vue';
import TransferCaseDialog from '@/views/AccountPage/components/TransferCaseDialog.vue';
import { EventBus } from '@/event-bus.js';

export default {
  props: ['subjectState'],
  components: {
    PrintSubject,
    PrintVaccineRegistration,
    ConfirmDeletePatientCaseDialog,
    TransferCaseDialog
  },
  computed: {
    ...mapGetters(['caseSelectedData']),
    cannotAddContactTracing() {
      return this.caseSelectedData.relationType && this.caseSelectedData.relationType == 'child';
    },
    vaccineDataPatientCase() {
      return this.$store.state.vaccine.vaccineDataPatientCase;
    }
  },

  data() {
    return {
      formData: null,
      consentFormData: null,
      patientCase: null,
      registrationType: null,
      noAppointmentText: 'User has no vaccine appointment, please create one first',
      dialogConfirmDeletePatientCase: false,
      dialogTransferCase: false,
      showFormFilter: false
    };
  },
  methods: {
    formFilterDisplay() {
      if (this.showFormFilter) {
        this.clearFilter();
        this.showFormFilter = false;
      } else {
        this.showFormFilter = true;
        setTimeout(() => {
          this.$refs['form-filter-input'].focus();
        }, 100);
      }
    },
    goToIntakeForm() {
      this.$router.push(`/intake-form/${this.caseSelectedData.id}`);
    },
    goToVisitorScan() {
      this.$router.push(`/visitor-scan/${this.caseSelectedData.id}`);
    },
    goToVaccineScan() {
      // if (this.caseSelectedData.vaccine) this.$router.push(`/vaccine-scan/${this.caseSelectedData.id}`);
      // else this.$store.commit('SET_SNACKBAR', { open: true, text: this.noAppointmentText, color: 'error' });
      this.$router.push(`/vaccine-scan/${this.caseSelectedData.id}`);
    },
    goToVaccineCheckinScan() {
      if (this.vaccineDataPatientCase) this.$router.push(`/vaccine-checkin-scan/${this.caseSelectedData.id}`);
      else this.$store.commit('SET_SNACKBAR', { open: true, text: this.noAppointmentText, color: 'error' });
    },
    filterFormElemts(searchInput) {
      const columns = document.querySelectorAll('.filter-col');
      for (var i = 0; i < columns.length; i++) {
        let label = columns[i].getElementsByTagName('label');
        if (label && label[0] && label[0].innerText && searchInput && searchInput.length > 2) {
          columns[i].classList.add('hide');
          let labelText = label[0].textContent.toLowerCase();
          if (labelText.includes(searchInput.toLowerCase())) columns[i].classList.remove('hide');
        } else {
          columns[i].classList.remove('hide');
        }
      }
    },
    clearFilter() {
      const columns = document.querySelectorAll('.filter-col');
      for (var i = 0; i < columns.length; i++) {
        columns[i].classList.remove('hide');
      }
    },
    printRegistration() {
      this.formData = this.mapFormDataForPrint();
      this.patientCase = this.caseSelectedData.id;

      setTimeout(() => {
        this.$htmlToPaper('printSubjectTable');
      }, 400);
    },
    async printVaccineRegistration() {
      this.consentFormData = null;
      this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'One moment...' });
      let requestData = null;
      try {
        //make request call to get consent form data and check if user registered
        requestData = await this.$store.dispatch('GET_VACCINE_REQUEST_HISTORIC', this.caseSelectedData.id);
        if (this.$store.state.doctorOptions && this.$store.state.doctorOptions.length == 0) await this.$store.dispatch('getDoctorOptions');
      } catch {
        this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
      }
      if (requestData && requestData.length > 0) {
        this.consentFormData = JSON.parse(requestData[0].data);
      } else {
        this.$store.commit('SET_SNACKBAR', { open: true, text: 'User not registered for vaccine', color: 'error' });
        this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
        return;
      }
      this.formData = this.mapFormDataForPrint();
      this.patientCase = this.caseSelectedData.id;

      setTimeout(() => {
        this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
        this.$htmlToPaper('PrintVaccineRegistration');
      }, 400);
    },
    mapFormDataForPrint() {
      const data = this.caseSelectedData;

      return {
        dateOfBirth: data.birthDate ? data.birthDate : null,
        email: data.email ? data.email : null,
        firstName: data.firstname ? data.firstname : null,
        lastName: data.lastname ? data.lastname : null,
        phoneNumber: data.phoneNumber ? data.phoneNumber : null
      };
    },
    copyPatientCase() {
      navigator.clipboard.writeText(this.caseSelectedData.id).then(
        () => {
          this.$store.commit('SET_SNACKBAR', { open: true, text: 'Patientcase copied to clipboard', color: 'success' });
        },
        (err) => {
          console.error('Async: Could not copy text: ', err);
        }
      );
    },
    openSubjectFormDialog(withParentLink) {
      EventBus.$emit('openSubjectFormDialog', withParentLink);
    },
    transferCaseDialog() {
      this.dialogTransferCase = true;
    },
    searchCase() {
      this.$store.commit('SET_QUARANTINED_SUBJECTS', []);
      this.$router.push({ path: '/', query: { q: this.caseSelectedData.name } }).catch();
    }
  }
};
</script>

<style lang="scss">
.filter-col {
  &.hide {
    display: none;
  }
}

.case-menu-btn,
.subject-menu-btn {
  float: right;
}
.row-header {
  border-bottom: 1px solid lightgrey;
  margin-bottom: 10px;
}
</style>