<template>
  <v-dialog v-model="dialogNote" width="900">
    <v-card class="note-dialog">
      <v-card-title class="headline primary white--text" primary-title> Note for {{ caseSelectedData.name }} </v-card-title>

      <div class="pa-4">
        <v-list-item-title class="headline mb-1 primary--text text--darken-1">Check in values</v-list-item-title>
        <v-row>
          <v-col cols="12" sm="6">
            <img v-if="selectedNote && selectedNote.thumbnail" :src="selectedNote.thumbnail" style="width: 100%" />
            <div v-else class="subtitle-1">No image submitted</div>
          </v-col>
          <v-col cols="12" sm="6">
            <v-list-item-subtitle class="small-sub-title">Check in date and time</v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1">{{
              selectedNote.dateTimeFormatted ? selectedNote.dateTimeFormatted : 'No value submitted'
            }}</v-list-item-subtitle>
            <v-list-item-subtitle class="small-sub-title">Body Temperature</v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1">{{ selectedNote.bodyTemp ? selectedNote.bodyTemp : 'No value submitted' }}</v-list-item-subtitle>
            <v-list-item-subtitle class="small-sub-title">Intake Method</v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1">{{
              selectedNote.bodyTempMethod ? selectedNote.bodyTempMethod : 'No value submitted'
            }}</v-list-item-subtitle>
            <v-list-item-subtitle class="small-sub-title">Symptoms</v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1">{{
              selectedNote.symptomsList ? selectedNote.symptomsList : 'No value submitted'
            }}</v-list-item-subtitle>
            <v-list-item-subtitle class="small-sub-title">Coordinates</v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1" v-if="!showMap">No location data submitted</v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1" v-else>{{ this.lat }} {{ this.lng }}</v-list-item-subtitle>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8" offset-md="2" v-if="showMap">
            <div class="title primary--text">Map Location</div>
            <l-map :zoom="zoom" :center="center" :options="mapOptions">
              <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
              <l-marker :lat-lng="marker"></l-marker>
            </l-map>
          </v-col>
        </v-row>
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialogNote = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import L from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { Icon } from 'leaflet';
import moment from 'moment';
import { mapGetters } from 'vuex';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  ...mapGetters(['caseSelectedData']),
  props: ['selectedNote', 'openNoteDialogFromParent'],
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  data() {
    return {
      showMap: false,
      dialogNote: false,
      zoom: 40,
      lat: null,
      lng: null,
      center: null,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: null,
      mapOptions: {
        scrollWheelZoom: false
      }
    };
  },
  created() {
    if (this.openNoteDialogFromParent) {
      this.dialogNote = true;
      if (this.selectedNote && this.selectedNote.gpsLat && this.selectedNote.gpsLon) {
        this.lat = this.selectedNote.gpsLat;
        this.lng = this.selectedNote.gpsLon;
        this.setMapLocation();
        setTimeout(() => {
          this.showMap = true;
        }, 0);
      } else {
        this.showMap = false;
      }
      if (this.selectedNote && this.selectedNote.insertDate) {
        this.selectedNote.dateTimeFormatted = moment(this.selectedNote.insertDate, 'YYYY-MM-DDTHH:mm')
          .subtract(4, 'hours')
          .format('DD-MMM-YYYY | h:mm A');
      }
    }
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.center = center;
    },

    setMapLocation() {
      console.log(this.lat, this.lng);
      this.marker = L.latLng(this.lat, this.lng);
      this.center = L.latLng(this.lat, this.lng);
      this.zoomUpdate(40);
      this.centerUpdate(L.latLng(this.lat, this.lng));
    }
  }
};
</script>

<style lang="scss">
.note-dialog {
  .small-sub-title {
    //font-size: 12px !important;
    color: #366baf !important;

    font-weight: 600;
    margin-top: 5px;
  }
}
</style>