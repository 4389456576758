<template>
  <v-menu
    ref="menuDate"
    v-model="menuDate"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    persistent
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="computedTime"
        :label="label"
        readonly
        :disabled="disabled"
        clearable
        @click:clear="clearTime()"
        :class="required ? 'required' : ''"
        required
        :rules="timePickerRules"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker v-model="time">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menuDate = false">Cancel</v-btn>
      <v-btn text color="primary" @click="saveTime(time)">OK</v-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  props: ['keyName', 'label', 'required', 'valueFromParent', 'disabled'],
  data() {
    return {
      time: null,
      menuDate: false,
      timePickerRules: [],
    };
  },
  watch: {},
  computed: {
    computedTime() {
      if (this.time) return moment(this.time, 'HH:mm').format('h:mm A');
      else return null;
    },
  },
  created() {
    if (this.valueFromParent) {
      this.time = moment.utc(this.valueFromParent, 'YYYY-MM-DDTHH:mm').local().format('h:mm A');
    }
    if (this.required) this.timePickerRules = [(v) => !!v || 'This field is required'];
  },
  methods: {
    saveTime(time) {
      this.$refs.menuDate.save(time);
      this.$emit('updateParentValue', time, this.keyName);
    },
    clearTime() {
      this.time = null;
      this.$emit('updateParentValue', null, this.keyName);
    },
  },
};
</script>

<style lang="scss">
</style>