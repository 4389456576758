<template>  
    <v-select
        :value="district"
        :items="selectItems"
        :rules="validationRules"
        :class="required ? 'required':''"
        @change="saveValue($event)"
        color='primary'
        label="District">
    </v-select>
</template>

<script>


export default {
    props: ['required', 'valueFromParent', 'keyName'],
    data() {
        return {
            validationRules: [],
            district: null,
            selectItems: ['Noord', 'Oranjestad', 'Paradera', 'San Nicolaas', 'Santa Cruz', 'Savaneta', 'Tanki Leendert']
        }
    },
    created() {
        if(this.required)
            this.validationRules= [v => !!v || 'This field is required'];
        if(this.valueFromParent)
            this.district = this.valueFromParent;
    },
    methods: {
        saveValue(value) {
            this.$parent.$parent.$parent.updateValue(value, this.keyName);
        }
    },

}
</script>