<template>
  <v-container fluid v-if="hasAccessToPage" class="patient-case-page">
    <v-row justify="center" class="mb-4 mt-0">
      <v-col cols="10" sm="12" md="12" lg="12" xl="10" style="position: relative">
        <!-- ACCOUNT DATA -->
        <AccountData @openFormDialog="openFormDialog" />

        <!-- CASES TABS -->
        <v-tabs v-model="tabModel" class="elevation-0 tabbar-header" :class="casesData ? '' : 'empty-tabs'" show-arrows icons-and-text center-active>
          <!-- TABS -->
          <v-tooltip bottom v-for="subjectCase in casesData" :key="'tab-header-' + subjectCase.patientCaseId">
            <template v-slot:activator="{ on, attrs }">
              <v-tab
                :href="`#${subjectCase.patientCaseId}`"
                :class="'tab-' + subjectCase.patientCaseId"
                v-model="tabModel"
                @click="tabSelected(subjectCase.patientCaseId)"
                v-bind="attrs"
                v-on="on"
                :key="subjectCase.patientCaseId"
              >
                <v-badge content="New" color="success" v-if="newlyAddedCaseIdLocal == subjectCase.patientCaseId" class="new-badge"></v-badge>
                {{ subjectCase.caseName }}
                <v-icon v-if="subjectCase.patientRelation" color="warning">mdi-folder-network</v-icon>
                <v-icon v-else>mdi-folder</v-icon>
              </v-tab>
            </template>
            <span>{{ subjectCase.patientCaseFirstName }} {{ subjectCase.patientCaseLastName }}</span>
          </v-tooltip>
          <div v-if="casesLoading" class="d-flex">
            <v-skeleton-loader
              v-for="(page, i) in 10"
              :key="'skeletonloader-' + i"
              type="button"
              ref="skeleton"
              class="mx-4 my-auto"
            ></v-skeleton-loader>
          </div>
        </v-tabs>

        <!-- TAB ITEMS -->
        <v-row justify="center" class="mt-3">
          <!-- LEFT COLUMN -->
          <v-col cols="12" sm="12" md="7" lg="7" xl="7">
            <CaseSelectedFormHeader :subjectState="subjectSelectedCaseComputed" />
            <CaseSelectedForm :newCase="false" :formDataParent="caseSelectedData" v-if="caseSelectedData.id && !loadingCases" />
            <CaseSelectedFormLoader v-else />
          </v-col>

          <!-- RIGHT COLUMN -->
          <v-col cols="12" sm="12" md="5" lg="5" xl="5">
            <VaccineInfoBlock />
            <PatientTestkitInfoBlock />
            <TravelInfoBlock />
            <DccInfoBlock v-if="userHasRole('admin')" />
            <NotesCardBlock />
            <!-- MAP CARD -->
            <v-card v-if="caseSelectedData && caseSelectedData.addressLocation && mapVisible">
              <l-map :zoom="zoom" :center="center" :options="mapOptions">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-marker :lat-lng="marker"></l-marker>
              </l-map>
            </v-card>
          </v-col>
        </v-row>

        <!-- EMPTY CASES -->
        <div v-if="casesData.length == 0 && !loadingCases" class="elevation-0 grey--text no-cases" justify="center">
          <div class="display-1">NO CASES</div>
          <v-btn color="primary elevation-0" class="mt-3" x-large fab center @click.stop="openFormDialog()">
            <v-icon>mdi-folder-plus</v-icon>
          </v-btn>
          <div class="mt-1">Add case</div>
        </div>

        <!-- DIALOG CREATE CASE -->
        <v-dialog v-model="dialog" width="800" scrollable persistent>
          <v-card>
            <v-card-title class="headline primary white--text mb-4" primary-title>{{ caseFormDialogTitle }}</v-card-title>

            <v-card-text>
              <v-alert v-if="withParentLink" color="warning" text icon="mdi-alert-circle">
                New case will be created linked to {{ caseSelectedData.name }}.
              </v-alert>
              <CaseSelectedForm ref="createNewCaseFormComponent" :newCase="true" v-if="showCaseForm" :withParentLink="withParentLink" />
            </v-card-text>

            <v-divider></v-divider>
            <v-alert type="error" v-if="dialogError" dense dismissible>Please fill in all required fields</v-alert>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false" :disabled="checkingAzvNumber">Cancel</v-btn>
              <v-btn color="primary" @click="addNewCase()" :disabled="creatingNewCase || checkingAzvNumber" :loading="creatingNewCase">Create</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- DIALOG EDIT MAIN USER -->
        <AccountFromDialog :dialogUser="dialogUser" v-if="dialogUser" @dialogUserResendCredentialsChanged="dialogUserResendCredentialsChanged" />

        <!-- DIALOG RESEND CREDENTIALS USER -->
        <UserResndCredentialsDialog
          :dialogUserProp="dialogUserResendCredentials"
          v-if="dialogUserResendCredentials"
          @dialogUserResendCredentialsChanged="dialogUserResendCredentialsChanged"
        />
      </v-col>
    </v-row>
  </v-container>
  <NoAccessMessage v-else />
</template>

<script>
import CaseSelectedForm from '@/views/AccountPage/components/CaseSelectedForm.vue';
import AccountFromDialog from '@/components/account-and-case-components/account-form-dialog.vue';
import NotesCardBlock from '@/views/AccountPage/NotesCardBlock/NotesCardBlock.vue';
import UserResndCredentialsDialog from '@/components/account-and-case-components/user-resend-credentials-dialog.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
import quarantineStatesJson from '@/store/data/quarantine-states.json';
import L from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { Icon } from 'leaflet';
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import NoAccessMessage from '@/components/generic/no-access-message.vue';
import PatientTestkitInfoBlock from '@/views/AccountPage/components/PatientTestkitInfoBlock.vue';
import VaccineInfoBlock from '@/views/AccountPage/VaccineInfoBlock/VaccineInfoBlock.vue';
import AccountData from '@/views/AccountPage/components/AccountData.vue';
import { EventBus } from '@/event-bus.js';
//import { getLastSegmentFromPath } from '@/utils/functions.js';
import CaseSelectedFormHeader from '@/views/AccountPage/components/CaseSelectedFormHeader.vue';
import CaseSelectedFormLoader from '@/views/AccountPage/components/CaseSelectedFormLoader.vue';
import TravelInfoBlock from '@/views/AccountPage/TravelInfoBlock/TravelInfoBlock.vue';
import DccInfoBlock from '@/views/AccountPage/DccInfoBlock/DccInfoBlock.vue';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  mixins: [SharedFunctions],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    CaseSelectedForm,
    NotesCardBlock,
    AccountFromDialog,
    UserResndCredentialsDialog,
    NoAccessMessage,
    PatientTestkitInfoBlock,
    VaccineInfoBlock,
    AccountData,
    CaseSelectedFormHeader,
    CaseSelectedFormLoader,
    TravelInfoBlock,
    DccInfoBlock
  },
  computed: {
    ...mapGetters(['accountData', 'casesData', 'caseSelectedData', 'selectedCaseNotes', 'accountEditDelay', 'newlyAddedCaseId']),
    createdDateSelectedSubject() {
      return moment(this.accountData.createdTimestamp).format('MM/DD/YYYY');
    },
    subjectSelectedCaseComputed() {
      let state = this.quarantineStatesJson.filter((subjectCase) => {
        return subjectCase.value == this.caseSelectedData.state;
      });
      if (state && state.length > 0) return state[0].text;
      else return null;
    },
    phoneNumber() {
      let phoneNumber = null;
      if (this.accountData && this.accountData.attributes && this.accountData.attributes.phoneNumber)
        return this.accountData.attributes.phoneNumber[0];
      else return phoneNumber;
    },
    caseSelectedData() {
      return this.$store.state.accountData.caseSelectedData;
    }
  },
  watch: {
    caseSelectedData(n, o) {
      if (n && n.id && n != o) {
        if (n.addressLocation) {
          let latLng = n.addressLocation.split(',');
          this.lat = parseFloat(latLng[0]);
          this.lng = parseFloat(latLng[1]);
          this.setMapLocation();
        }
      }
    }
  },
  data() {
    return {
      dialogUser: false,
      dialogUserResendCredentials: false,
      showCaseForm: false,
      mapVisible: true,
      dialog: false,
      dialogError: false,
      icons: true,
      currentNoteData: null,
      loadingCases: false,
      quarantineStatesJson: quarantineStatesJson,
      zoom: 40,
      lat: null,
      lng: null,
      center: null,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: null,
      mapOptions: {
        scrollWheelZoom: false
      },
      hasAccessToPage: false,
      creatingNewCase: false,
      checkingAzvNumber: false,
      withParentLink: false,
      caseFormDialogTitle: null,
      tabModel: null,
      casesLoading: false,
      newlyAddedCaseIdLocal: false
    };
  },
  async created() {
    this.hasAccessToPage = this.hasAccessToPageMixin();
    this.handleUrlParams();
    this.getCaseSelectedData();
    this.zoomUpdate(40);
    this.listenFormFormDialogOpen();
    this.handleNewIndicator();
  },
  beforeDestroy() {
    this.clearAllDataPatientCasePage();
    EventBus.$off('openSubjectFormDialog', (withParentLink) => {
      this.openFormDialog(withParentLink);
    });
  },
  methods: {
    async getCaseSelectedData() {
      if (this.$route.params?.caseId) {
        this.loadingCases = true;
        this.tabModel = this.$route.params?.caseId;
        let patientCase;
        try {
          patientCase = await this.$store.dispatch('getCaseSelectedData', this.$route.params.caseId);
        } catch (error) {
          console.error(error);
        } finally {
          this.loadingCases = false;
        }

        if (patientCase?.id) {
          this.$store.dispatch('getStateHistory', patientCase.id);
        }
      }
    },
    async addNewCase() {
      this.dialogError = false;
      let formData = this.$refs.createNewCaseFormComponent.formData;
      formData = this.cleanObj(formData);
      const errorAzvAreadyInUse = this.$refs.createNewCaseFormComponent.errorAzvAreadyInUse;
      if (!formData.keycloakId) formData.keycloakId = this.accountData.id;
      if (this.withParentLink) {
        formData.parentCase = this.caseSelectedData.id;
        formData.keycloakId = '';
      }
      if (this.$refs.createNewCaseFormComponent.$refs['form-selected-subject-case'].validate() && !errorAzvAreadyInUse) {
        this.creatingNewCase = true;
        this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'Creating new case' });
        this.$store
          .dispatch('createCase', formData)
          .then((data) => {
            this.newlyAddedCaseIdLocal = data.id;
            this.$store.dispatch('getStateHistory', this.caseSelectedData.id);
            this.$store.commit('SET_ACCOUNT_UPDATED', true);
            setTimeout(async () => {
              this.dialog = false;
              this.$store.commit('SET_SHOW_LOADER', { action: false, text: '' });
              await this.$store.dispatch('getAccountData', this.$route.params.accountId);
            }, this.accountEditDelay);
          })
          .finally(() => (this.creatingNewCase = false));
      } else {
        this.dialogError = true;
      }
    },
    openFormDialog(withParentLink = false) {
      if (withParentLink) {
        this.withParentLink = true;
        this.caseFormDialogTitle = `Create new case linked to ${this.caseSelectedData.name}`;
      } else {
        this.withParentLink = false;
        this.caseFormDialogTitle = 'Create New Local Case';
      }
      this.showCaseForm = false;
      setTimeout(() => {
        this.showCaseForm = true;
        this.dialog = true;
      }, 0);
    },
    tabSelected(caseId) {
      this.clearAllDataPatientCasePage();
      this.$router.push({ name: 'AccountPage', params: { accountId: this.accountData.keycloakId, caseId: caseId } }).catch(() => 'router catch');
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.center = center;
    },
    setMapLocation() {
      this.marker = L.latLng(this.lat, this.lng);
      this.center = L.latLng(this.lat, this.lng);
      this.zoomUpdate(40);
      this.centerUpdate(L.latLng(this.lat, this.lng));
    },
    cleanObj(obj) {
      obj = JSON.parse(JSON.stringify(obj));
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      if (!obj.doctorEntry?.id) delete obj.doctorEntry;
      return obj;
    },
    dialogUserChanged(value) {
      this.dialogUser = value;
    },
    dialogUserResendCredentialsChanged(value) {
      this.dialogUserResendCredentials = value;
    },
    listenFormFormDialogOpen() {
      EventBus.$on('openSubjectFormDialog', (withParentLink) => {
        this.openFormDialog(withParentLink);
      });
    },
    pushNewTabIndexUrl(i) {
      const indexLastSlash = this.$route.fullPath.lastIndexOf('/');
      const newPath = this.$route.fullPath.substring(0, indexLastSlash) + '/' + i;
      window.history.replaceState(null, null, newPath);
    },
    async handleUrlParams() {
      if (this.$route.params.accountId && Object.keys(this.accountData).length === 0) {
        try {
          this.casesLoading = true;
          await this.$store.dispatch('getAccountData', this.$route.params.accountId);
        } finally {
          this.casesLoading = false;
        }
      }
    },
    clearAllDataPatientCasePage() {
      this.$store.commit('SET_CASE_SELECTED_DATA', {});
      this.$store.commit('SET_VACCINE_DATA_PATIENT_CASE', null);
      this.$store.commit('SET_SUBJECT_CASE_TESTKIT_INFO', []);
    },
    handleNewIndicator() {
      if (this.newlyAddedCaseId) {
        this.newlyAddedCaseIdLocal = this.newlyAddedCaseId;
        this.$store.commit('SET_NEWLY_ADDED_CASE_ID', null);
      }
    }
  }
};
</script>

<style lang="scss">
.patient-case-page {
  .v-tabs-items {
    background: none;
  }
  .v-slide-group__wrapper {
    //background: lighten(#e8f2ff, 0%);
    background: white;
  }
  .page-empty {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .tabbar-header {
    .v-tabs-bar {
      background-color: transparent !important;
      border-bottom: 1px solid lighten(lightgrey, 5%);
    }
    .new-badge {
      position: absolute;
      z-index: 100;
      top: 20px;
      right: 56px;
    }
  }

  .vue2leaflet-map {
    margin-top: 20px;
    height: 500px !important;
    z-index: 1;
  }

  .no-cases {
    height: 250px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .empty-tabs {
    .v-tabs-slider-wrapper {
      display: none;
    }
  }
}
</style>
