<template>
  <v-dialog v-model="dialogUser" width="600" scrollable>
    <v-card>
      <v-card-title class="headline" error-title> Reset Credentials </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-4">
        <h3>User's password will be reset and sent to their email or through an SMS notification.</h3>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialogUser = false"> Cancel </v-btn>
        <v-btn color="error" @click="resendUserCredentials()"> Reset and send credentials </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: ['dialogUserProp'],
  components: {},
  data() {
    return {
      dialogUser: false,
      dialogError: false
    };
  },
  computed: {
    ...mapGetters(['accountData'])
  },
  watch: {
    dialogUser(n, o) {
      if (n != o) {
        this.$emit('dialogUserResendCredentialsChanged', n);
      }
    }
  },
  created() {
    this.dialogUser = this.dialogUserProp;
  },
  methods: {
    resendUserCredentials() {
      const payload = {
        id: this.accountData.keycloakId,
        enabled: 'true'
      };
      this.$store.dispatch('editUser', payload).then(() => {
        const actions = ['UPDATE_PASSWORD'];
        const payload = {
          id: this.accountData.keycloakId,
          action: actions
        };
        this.$store.dispatch('resendUserCredentials', payload).then(async () => {
          this.dialogUser = false;
          await this.$store.dispatch('getAccountData', this.$route.params.accountId);
        });
      });
    }
  }
};
</script>

<style></style>
