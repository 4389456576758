<template>
  <div class="pt-4">
    <v-row>
      <v-col cols="12" md="10" offset-md="1" class="pb-0 filter-col">
        <label class="primary--text title">State History</label>
        <v-divider class="title-border mb-0 pb-0"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="filter-col" cols="12" md="10" offset-md="1">
        <label style="display: none">State History</label>
        <v-simple-table class="mb-6" hover style="background: whitesmoke" dense v-if="stateHistory && stateHistory.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">State</th>
                <th class="text-left">Set by</th>
                <th class="text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(stateObj, i) in stateHistory" :key="'state-history-' + i">
                <td>{{ getStateName(stateObj.state) }}</td>
                <td>{{ stateObj.changedBy }}</td>
                <td>{{ setReadebleTime(stateObj.changedDate) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div v-else-if="stateHistory && stateHistory.length == 0">No results</div>
        <v-progress-linear v-else indeterminate color="primary"></v-progress-linear>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';
import quarantineStatesJson from '@/store/data/quarantine-states.json';
export default {
  data() {
    return {
      states: quarantineStatesJson,
    };
  },
  computed: {
    stateHistory() {
      return this.$store.state.subjectSelectedCaseHistory;
    },
  },
  methods: {
    getStateName(id) {
      return this.states.filter((state) => state.value == id)[0].text;
    },
    setReadebleTime(serverTime) {
      if (serverTime) return moment(serverTime, 'YYYY-MM-DDTHH:mm').subtract(4, 'hours').format('DD-MMM-YYYY | h:mm A');
      else return 'No date set';
    },
  },
};
</script>

<style>
</style>