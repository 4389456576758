<template>
  <v-dialog v-model="dialogConfirmDeletePatientCase" width="500" persistent>
    <v-card>
      <v-card-title class="headline error white--text" primary-title> Delete case </v-card-title>

      <v-card-text class="pt-4"> Type "DELETE" to confirm the deletion of this case. </v-card-text>
      <v-text-field v-model="deleteText" outlined dense class="mx-4"></v-text-field>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeModal()" :disabled="loading"> Cancel </v-btn>
        <v-btn color="error" elevation="0" @click="deletePatienCase()" :disabled="canDelete || loading" :loading="loading"> Delete </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: ['dialogConfirmDeletePatientCase', 'case'],
  data() {
    return {
      deleteText: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters(['casesData', 'accountEditDelay']),
    canDelete() {
      if (this.deleteText === 'DELETE') return false;
      else return true;
    }
  },
  created() {},
  methods: {
    closeModal() {
      this.$parent.dialogConfirmDeletePatientCase = false;
    },
    async deletePatienCase() {
      this.loading = true;
      try {
        await this.$store.dispatch('deletePatientCase', this.case.id);
        await this.$store.dispatch('getAccountData', this.$route.params.accountId);
        this.$store.commit('SET_SNACKBAR', { open: true, text: 'Case successfully deleted', color: 'success' });
        this.closeModal();
        const caseIdToSelectAfterDelete = this.casesData.length > 0 ? this.casesData[0].patientCaseId : null;
        this.$router
          .push({ name: 'AccountPage', params: { accountId: this.$route.params.accountId, caseId: caseIdToSelectAfterDelete } })
          .catch(() => 'router catch');
      } finally {
        setTimeout(() => {}, this.accountEditDelay);
        this.loading = false;
      }
    }
  }
};
</script>

<style>
</style>