<template>
  <v-card>
    <v-card-text>
      <div class="d-flex align-center">
        <v-card-title class="pa-1"> Dosage {{ vaccineNumber }} </v-card-title>
        <v-chip outlined v-if="vaccineNumber == '3'" small class="ms-2" color="warning font-weight-bold">Booster shot</v-chip>

        <v-spacer />
        <v-chip color="error" v-if="hasFirstDosage">Has first dosage already</v-chip>
        <v-chip color="error" v-else-if="hasSecondDosage">Has second dosage already</v-chip>
        <v-chip color="error" v-else-if="hasThirdDosage">Has third dosage already</v-chip>
        <v-chip color="error" v-else-if="hasFourthDosage">Has fourth dosage already</v-chip>
        <v-chip color="error" v-else-if="hasFifthDosage">Has fifth dosage already</v-chip>
        <v-chip color="error" v-else-if="hasSixthDosage">Has sixth dosage already</v-chip>
        <v-chip color="error" v-else-if="hasSeventhDosage">Has seventh dosage already</v-chip>
        <v-chip color="error" v-else-if="hasEighthDosage">Has eighth dosage already</v-chip>
        <v-switch class="mt-0" v-model="vaccineActive" :label="'Active'" inset v-else />
      </div>
      <v-form :ref="`vaccine-registration-card-${vaccineNumber}`">
        <!-- DATE -->
        <v-dialog
          ref="dialogVaccineRegistrationDate"
          v-model="dialogVaccineRegistrationDate"
          :close-on-content-click="true"
          transition="scale-transition"
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :class="required ? 'required' : ''"
              :value="computedAppointmentDate"
              required
              clearable
              label="Appointment date"
              append-icon="mdi-calendar"
              readonly
              v-on="on"
              :disabled="!vaccineActive"
              class="required"
              :rules="[(v) => !!v || 'This field is required']"
            ></v-text-field>
          </template>
          <v-date-picker ref="picker" v-model="date" min="1900-01-01" @change="dateChosen"></v-date-picker>
        </v-dialog>

        <!-- NURSE -->
        <v-text-field
          :label="'Nurse full name'"
          :class="'required'"
          :rules="[(v) => !!v || 'This field is required']"
          required
          v-model="formData.nurseName"
          append-icon="mdi-doctor"
          :disabled="!vaccineActive"
        ></v-text-field>

        <!-- LOCATION -->
        <v-select
          :disabled="!vaccineActive"
          :class="'required'"
          append-icon="mdi-map-marker"
          v-model="formData.selectedLocation"
          :loading="loadingLocations || vaccineInventoryLoader"
          :rules="[(v) => !!v || 'This field is required']"
          @change="setLocation($event)"
          :items="vaccineLocations"
          item-value="id"
          item-text="name"
          label="Location"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.address"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-select>

        <div v-if="vaccineInventory && vaccineInventory.length > 0" style="width: 100%" class="mt-3">
          <!-- INVENTORY CARD -->
          <div class="subtitle-1 mb-1 error--text font-weight-medium" v-if="inventoryMessage">Please select inventory below:</div>
          <div v-for="box in vaccineInventory" :key="box.id">
            <InventoryCard :box="box" :selectedBox="selectedBox" @setSelectedBox="setSelectedBox" />
          </div>
        </div>
        <div v-else-if="vaccineInventory && vaccineInventory.length == 0" class="full-width text-center align-self-center">
          <v-card class="py-4">
            <v-card-text class="primary--text">
              <div class="subtitle-1 font-weight-medium">No vaccines boxes at this location</div>
              <div class="error--text">Please select another location to continue</div>
            </v-card-text>
          </v-card>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment';
import InventoryCard from '@/views/VaccineViews/VaccineCheckoutPage/components/InventoryCard.vue';
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import { EventBus } from '@/event-bus.js';

export default {
  mixins: [SharedFunctions],
  props: ['canActivate', 'vaccineLocations', 'vaccineNumber', 'hasFirstDosage', 'hasSecondDosage', 'hasThirdDosage', 'hasFourthDosage', 
  'hasFifthDosage', 'hasSixthDosage', 'hasSeventhDosage', 'hasEighthDosage'],
  components: {
    InventoryCard
  },
  data() {
    return {
      dialogVaccineRegistrationDate: false,
      registering: false,
      vaccineActive: false,
      loadingLocations: false,
      selectedBox: { id: null },
      vaccineInventory: null,
      vaccineInventoryLoader: false,
      date: null,
      formValid: false,
      inventoryMessage: false,
      formData: {
        date: null,
        nurseName: null,
        selectedLocation: null,
        inventoryIdVaccine: null
      }
    };
  },
  computed: {
    computedAppointmentDate() {
      return this.formData.date ? moment(this.formData.date, 'YYYY-MM-DD').format('D-MMMM-YYYY') : '';
    }
  },
  watch: {
    vaccineActive(n) {
      if (n !== null) {
        this.$store.commit('SET_VACCINE_REGISTRATION_DIALOG_DATA', { key: `vaccine${this.vaccineNumber}Active`, data: n });
        this.$emit("vaccineActivated", { nr: this.vaccineNumber, active: n });
      }
      if (n === false) {
        this.resetForm();
      }
    }
  },
  created() {
    EventBus.$on(`vaccine${this.vaccineNumber}RegistrationValidate`, this.validate);
    //this.resetForm();
  },
  beforeDestroy() {
    this.resetForm();
    EventBus.$off(`vaccine${this.vaccineNumber}RegistrationValidate`, this.validate);
  },
  methods: {
    dateChosen(date) {
      this.$refs.dialogVaccineRegistrationDate.save(date);
      this.formData.date = date + 'T13:00:00.000Z[UTC]';
    },
    setSelectedBox(box) {
      this.selectedBox = box;
      this.formData.inventoryIdVaccine = box.id;
    },
    setLocation(location) {
      this.vaccineInventoryLoader = true;
      this.$store
        .dispatch('getVaccineInventoryByLocation', location)
        .then((data) => {
          this.vaccineInventory = data;
        })
        .finally(() => (this.vaccineInventoryLoader = false));
    },
    resetForm() {
      this.formData.nurseName = null;
      this.formData.selectedLocation = null;
      this.formData.date = null;
      this.vaccineInventory = null;
      this.selectedBox.id = null;
      this.inventoryIdVaccine = null;
      this.vaccineActive = false;
      this.$store.commit('SET_VACCINE_REGISTRATION_DIALOG_DATA', { key: `vaccine${this.vaccineNumber}Active`, data: false });
      this.$store.commit('SET_VACCINE_REGISTRATION_DIALOG_DATA', { key: `form${this.vaccineNumber}Valid`, data: false });
      this.$store.commit('SET_VACCINE_REGISTRATION_DIALOG_DATA', { key: `form${this.vaccineNumber}Data`, data: this.formData });
    },
    validate() {
      if (this.validateForm() && this.validateInventorySelection()) {
        this.$store.commit('SET_VACCINE_REGISTRATION_DIALOG_DATA', { key: `form${this.vaccineNumber}Valid`, data: true });
        this.$store.commit('SET_VACCINE_REGISTRATION_DIALOG_DATA', { key: `form${this.vaccineNumber}Data`, data: this.formData });
      } else {
        this.$store.commit('SET_VACCINE_REGISTRATION_DIALOG_DATA', { key: `form${this.vaccineNumber}Valid`, data: false });
        this.$store.commit('SET_VACCINE_REGISTRATION_DIALOG_DATA', { key: `form${this.vaccineNumber}Data`, data: null });
      }
    },
    validateForm() {
      if (this.$refs[`vaccine-registration-card-${this.vaccineNumber}`] && this.$refs[`vaccine-registration-card-${this.vaccineNumber}`].validate()) {
        return true;
      } else {
        return false;
      }
    },
    validateInventorySelection() {
      if (this.selectedBox && this.selectedBox.id) {
        this.inventoryMessage = false;
        return true;
      } else {
        this.inventoryMessage = true;
        return false;
      }
    }
  }
};
</script>

<style>
</style>