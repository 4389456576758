<template>
  <div>
    <v-card outlined>
      <v-card-text class="pb-2">
        <div style="font-size: 16px" class="mt-0 mb-1">Parent Case(s)</div>
        <v-divider class="mb-2"></v-divider>

        <div class="mt-4 mb-4">
          <div class="loader mt-6" v-if="loadingParentCases">
            <div class="mb-1">Loading parent cases...</div>
            <v-progress-linear :size="30" color="primary" class indeterminate></v-progress-linear>
          </div>

          <v-alert
            dense
            class="my-2 d-flex white--text alert-parent-case"
            color="primary"
            text
            v-for="(parentCaseItem, i) in parentCases"
            :key="'case-' + i"
          >
            <div class="content d-flex">
              <v-icon left color="primary">mdi-folder</v-icon>
              <div class="text">
                <div class="name">{{ parentCaseItem.name }}</div>
                <div>{{ parentCaseItem.firstName }} {{ parentCaseItem.lastName }}</div>
                <div>{{ parentCaseItem.address }}</div>
              </div>
            </div>
            <template v-slot:close>
              <v-btn color="primary" class="pa-2 me-0" fab text x-small @click="removeParentCase(i)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-alert>
        </div>
      </v-card-text>

      <v-autocomplete
        dense
        class="mx-4 my-0"
        outlined
        v-model="selectedParentCase"
        @change="parentCaseSelected($event)"
        :items="items"
        :loading="isLoading"
        :disabled="loadingParentCases"
        :search-input.sync="search"
        no-filter
        clearable
        hide-selected
        label="Search Parent Case"
      >
        <!-- Drop down templates -->
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title v-if="emptyItems"> No cases found </v-list-item-title>
            <v-list-item-title v-else> Search by name or case number </v-list-item-title>
          </v-list-item>
        </template>

        <template v-slot:item="{ item }">
          <v-icon class="me-2" color="grey">mdi-folder</v-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item['case name']"></v-list-item-title>
            <v-list-item-subtitle v-text="item.firstName + ' ' + item.lastName + ' | ' + item.address"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <!-- / -->
      </v-autocomplete>

      <v-alert v-if="maxParentCasesReached" dense outlined type="error" class="mx-4" dismissible>
        Maximum amount of parent cases reached. Cannot add any more parent cases.
      </v-alert>
      <v-alert v-if="caseAlreadyInList" dense outlined type="info" class="mx-4" dismissible> Case already in list of selected parent cases </v-alert>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['dataFromParent'],
  data: () => ({
    caseAlreadyInList: false,
    maxParentCasesReached: false,
    isLoading: false,
    items: [],
    loadingParentCases: false,
    selectedParentCase: null,
    parentCases: [],
    parentCasesIds: [],
    search: null,
    inputText: null,
    emptyItems: false,
  }),

  watch: {
    search(n) {
      // Items have already been loaded
      if (!n || n.length < 1 || n == '' || typeof n !== 'string') return;

      this.isLoading = true;

      // Lazily load input items
      this.$store
        .dispatch('searchCase', { search: n })
        .then((res) => {
          if (res.length == 0) this.emptyItems = true;
          else this.emptyItems = false;
          this.items = res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    caseParent(n) {
      console.log(n);
    },
  },
  created() {
    if (this.dataFromParent) {
      this.setParentCases();
    }
  },
  methods: {
    parentCaseSelected(value) {
      console.log(value);
      setTimeout(() => {
        this.selectedParentCase = null;
        this.inputText = null;
      }, 0);
      if (value) {
        if (this.maxParentCasesExeeded(value)) this.maxParentCasesReached = true;
        else if (this.checkIfCaseAlreadyInList(value)) this.caseAlreadyInList = true;
        else {
          this.caseAlreadyInList = false;
          this.maxParentCasesReached = false;
          this.parentCasesIds.push(value.patientCaseId);
          value.name = value['case name'];
          this.parentCases.push(value);
          this.$emit('searchCaseSelected', this.parentCasesIds);
        }
      }
    },

    removeParentCase(i) {
      this.parentCasesIds.splice(i, 1);
      this.parentCases.splice(i, 1);
      this.$emit('searchCaseSelected', this.parentCasesIds);
      this.maxParentCasesReached = false;
      this.caseAlreadyInList = false;
    },

    maxParentCasesExeeded(addedCase) {
      let parentCasesClean = JSON.parse(JSON.stringify(this.parentCasesIds));
      parentCasesClean.push(addedCase);
      let maxCharsDb = 255;
      let selectedCaseString = parentCasesClean.join(',');
      if (selectedCaseString.length <= maxCharsDb) return false;
      else return true;
    },

    checkIfCaseAlreadyInList(addedCase) {
      if (this.parentCasesIds.includes(addedCase)) return true;
      else return false;
    },

    async setParentCases() {
      this.loadingParentCases = true;
      this.parentCasesIds = this.dataFromParent.split(',');
      try {
        let parentCases = [];
        this.parentCases = [];
        for await (const id of this.parentCasesIds) {
          let data = await this.$store.dispatch('getPatientCase', id);
          if (data) {
            data = {
              firstName: data.firstname,
              lastName: data.lastname,
              address: data.address,
              name: data.name,
            };

            parentCases.push(data);
          }
        }
        this.parentCases = parentCases;
      } finally {
        this.loadingParentCases = false;
      }
    },
  },
};
</script>

<style lang="scss">
.alert-parent-case {
  .v-alert__wrapper {
    width: 100%;
    .content {
      font-size: 0.8rem;
      line-height: 1.1rem;
      .name {
        font-size: 0.8rem;
        font-weight: 600;
      }
    }
  }
}
</style>
