<template>
  <v-dialog v-model="showRequestVaccineModal" persistent max-width="800" scrollable>
    <v-card class="received-form">
      <v-card-title class="headline primary white--text" primary-title>
        Request Vaccine
        <v-spacer />
        <v-btn color="white mt-0" fab text small @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <ConsentForm @closeModal="closeModal" parentType="requestVaccine" @nextStep="nextStepRequestVaccine" :submitting="submitting" />
    </v-card>
  </v-dialog>
</template>

<script>
import ConsentForm from '@/views/VaccineViews/VaccineRegistrationPage/components/ConsentForm.vue';

export default {
  props: ['showRequestVaccineModal', 'caseSelectedData'],
  components: { ConsentForm },
  data() {
    return { submitting: false };
  },
  methods: {
    closeModal() {
      this.$parent.showRequestVaccineModal = false;
    },
    async nextStepRequestVaccine(consentData) {
      const patientCaseId = this.caseSelectedData.id;

      const payload = {
        patientCase: {
          id: patientCaseId
        },
        data: JSON.stringify(consentData)
      };
      this.submitting = true;
      try {
        let requestResult = await this.$store.dispatch('REQUEST_VACCINE', payload);
        if (requestResult) this.closeModal();
        this.$emit('initVaccineDataParent', this.caseSelectedData.id);
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>

<style>
</style>