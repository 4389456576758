<template>
  <v-dialog v-model="openDialog" width="800" scrollable>
    <v-card>
      <v-card-title class="headline primary white--text" primary-title>
        {{ newNote ? 'Create New' : '' }} Note for {{ caseSelectedData.name }}
      </v-card-title>

      <v-card-text>
        <v-card class="elevation-0">
          <v-form v-model="formValid" ref="form-case-note">
            <v-alert type="error" v-if="formError" dense dismissible class="mt-0"> Please fill in all required fields </v-alert>
            <v-container style="padding-top: 40px">
              <v-row>
                <v-col cols="12" md="10" offset-md="1">
                  <v-textarea
                    outlined
                    :counter="maxCommentChars"
                    rows="3"
                    class="required"
                    :disabled="!newNote"
                    label="Comment"
                    :rules="rulesComment"
                    v-model="formData.createdNotes"
                  ></v-textarea>
                </v-col>
              </v-row>

              <!-- travel from and date -->
              <v-row>
                <v-col cols="12" md="5" offset-md="1">
                  <DatePicker
                    :valueFromParent="formData.callDate"
                    label="Call Date"
                    :disabled="!newNote"
                    keyName="callDate"
                    :required="true"
                    @updateParentValue="updateValue"
                  />
                </v-col>
                <v-col cols="12" md="5" offset-md="0">
                  <TimePicker
                    :valueFromParent="formData.callDate"
                    label="Call Date Time"
                    :disabled="!newNote"
                    keyName="callDateTime"
                    :required="true"
                    @updateParentValue="updateValue"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="5" offset-md="1">
                  <v-alert class="pt-5 pb-0" type="error" border="left" colored-border elevation="2">
                    <v-checkbox
                      v-model="alertCheckBox"
                      :disabled="!newNote"
                      class="py-0 my-0"
                      @change="addAlert($event)"
                      :label="`Alert`"
                      color="error"
                    ></v-checkbox>
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
        <div style="display: flex; justify-content: center">
          <v-img v-if="formDataParent && formDataParent.thumbnail" :src="formDataParent.thumbnail" style="max-width: 450px"></v-img>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <v-alert type="error" v-if="dialogError" dense dismissible class="mx-2 mb-0 mt-4">Please fill in all required fields</v-alert>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog()">{{ newNote ? 'Cancel' : 'Close' }}</v-btn>
        <v-btn color="primary" @click="addNewNote()" v-if="newNote">Create</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import DatePicker from '@/components/form-components/date-picker.vue';
import TimePicker from '@/components/form-components/time-picker.vue';

export default {
  components: {
    DatePicker,
    TimePicker
  },
  props: ['formDataParent', 'newNote', 'openDialog'],
  computed: {
    ...mapGetters(['caseSelectedData']),
    computedCallDate() {
      return this.formData.callDate ? moment(this.formData.callDate).format('D-MMMM-YYYY') : '';
    }
  },
  watch: {
    timeFromPicker(n, o) {
      if (n && n != o && this.formData.callDate) {
        let callDateSentFormat = this.formData.callDate.replace('01:00', this.timeFromPicker);
        if (callDateSentFormat) {
          this.$set(this.formData, 'callDate', callDateSentFormat);
        }
      }
    }
  },
  data() {
    return {
      alertCheckBox: false,
      formError: false,
      showEditAlert: false,
      formValid: null,
      maxCommentChars: 250,
      rulesComment: [(v) => v.length <= this.maxCommentChars || `Max ${this.maxCommentChars} characters`, (v) => !!v || 'This field is required'],
      timeFromPicker: null,
      formData: {
        id: null,
        createdNotes: '',
        callDate: null,
        insertDate: null,
        createdBy: null
      },
      dialogError: false
    };
  },
  created() {
    if (this.formDataParent) {
      this.formData = this.formDataParent;
      if (this.formDataParent.noteType == 3) {
        this.alertCheckBox = true;
      } else {
        this.alertCheckBox = false;
      }
    } else if (this.newNote) {
      this.formData.callDate = moment().utc().local();
    }
  },
  methods: {
    updateValue(value, keyName) {
      console.log(value, keyName);
      if (this.formData[keyName] === undefined && keyName != 'callDateTime') {
        this.$set(this.formData, keyName, null);
      }
      if (keyName == 'callDateTime') {
        this.timeFromPicker = value;
      } else this.formData[keyName] = value;
    },

    addAlert(value) {
      if (value) this.$set(this.formData, 'noteType', '3');
      else this.$delete(this.formData, 'noteType');
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    addNewNote() {
      this.dialogError = false;
      let formData = this.formData;
      if (this.$refs['form-case-note'].validate()) {
        let payload = {
          formData: {
            createdNotes: formData.createdNotes,
            callDate: formData.callDate
          },
          caseId: this.caseSelectedData.id
        };

        if (formData.noteType) {
          payload.formData.noteType = formData.noteType;
        }

        this.$store.dispatch('createNote', payload).then(() => {
          this.closeDialog();
          if (this.caseSelectedData.id) this.$emit('getAllNotes', this.caseSelectedData.id);
        });
      } else {
        this.dialogError = true;
      }
    }
  }
};
</script>