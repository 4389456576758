<template>
  <v-card class="elevation-1 bg-whitesmoke mb-8" v-if="caseSelectedData && casesData.length > 0">
    <!-- APPBAR -->
    <v-app-bar class="elevation-0 bg-whitesmoke app-bar align-center">
      <v-toolbar-title class="primary--text text-uppercase subtitle-1 font-weight-medium">
        <div class="d-flex align-center">
          <div class="icon-border-custom border-primary me-2" style="border-color: var(--v-primary-base)">
            <v-icon color="primary" left>mdi-tag</v-icon>
          </div>
          Case Notes
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" fab class="subject-menu-btn">
            <v-icon large class="mx-auto">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="openNoteFormDialog()">
            <v-list-content>
              <v-list-item-title> <v-icon left>mdi-tag-plus</v-icon>Create Tag </v-list-item-title>
            </v-list-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- NOTES TABE -->
    <v-data-table
      v-if="selectedCaseNotes.length > 0 && !loadingNotes"
      :headers="headers"
      :items="quarantineSubjectsLocal"
      :hide-default-footer="true"
    >
      <template v-if="selectedCaseNotes.length > 0" v-slot:item.thumb="{ item }">
        <v-icon class="py-3" v-if="item.noteType == '3'" color="error"> mdi-alert</v-icon>
        <v-icon class="py-3" v-else-if="item.noteType == '2'" color="success" medium> mdi-map-marker-check</v-icon>
        <v-icon class="py-3" v-else smalle> mdi-tag</v-icon>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn class="mx-2 primary--text" outlined small elevation="0" @click="editNote(item, selectedCaseNotes[item.index])">
          view <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <!-- Empty display -->
    <div class="pt-8" v-if="selectedCaseNotes.length == 0 && !loadingNotes" style="background: #fff; min-height: 80px; text-align: center">
      No Data
    </div>
    <!-- skeleton loader -->
    <div v-if="loadingNotes">
      <v-skeleton-loader v-for="(page, i) in 5" :key="'skeletonloader-' + i" type="list-item" ref="skeleton" class="mx-auto my-0"></v-skeleton-loader>
    </div>

    <v-divider></v-divider>
    <div class="text-center pa-2" style="background-color: #fff" v-if="$store.state.notesPageSize && selectedCaseNotes.length > 0">
      <v-pagination v-model="page" :length="parseInt($store.state.notesPageSize)"></v-pagination>
    </div>

    <!-- dialog for noteType 2, quarentine check  -->
    <CaseNoteQuarantineDialog v-if="openNoteDialog" :selectedNote="selectedNote" :openNoteDialogFromParent="openNoteDialog" />

    <!-- dialog for noteType 1 & 3 -->
    <CaseNoteDialog
      ref="createNewNoteFormComponent"
      :newNote="newNote"
      :formDataParent="currentNoteData"
      v-if="dialogNote"
      @closeDialog="closeDialog"
      @getAllNotes="getAllNotes"
      :openDialog="dialogNote"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import CaseNoteQuarantineDialog from '@/views/AccountPage/NotesCardBlock/components/CaseNoteQuarantineDialog.vue';
import CaseNoteDialog from '@/views/AccountPage/NotesCardBlock/components/CaseNoteDialog.vue';

export default {
  props: [],
  components: {
    CaseNoteDialog,
    CaseNoteQuarantineDialog
  },
  data() {
    return {
      page: 1,
      selectedNote: null,
      openNoteDialog: false,
      quarantineTypeFilter: ['From Abroad', 'Locally Transmitted'],
      search: '',
      headers: [
        { text: 'Type', align: 'start', sortable: false, value: 'thumb' },
        { text: 'Created By', value: 'name' },
        { text: 'Date Created', value: 'created' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      subjects: [],
      dialogNote: false,
      newNote: false,
      loadingNotes: false
    };
  },
  computed: {
    ...mapGetters(['selectedCaseNotes', 'caseSelectedData', 'casesData']),
    quarantineSubjectsLocal() {
      return this.selectedCaseNotes.map((note, index) => {
        return {
          index: index,
          //id_number: subject.id,
          thumb: 'tag',
          name: note.createdBy,
          created: moment(note.insertDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
          actions: null,
          noteType: note.noteType
        };
      });
    },
    userObject() {
      return this.$store.state.userObject;
    }
  },
  watch: {
    page(n, o) {
      if (n && n != o) {
        this.getNotes(n);
      }
    },
    caseSelectedData(n, o) {
      if (n && n.id && n != o) {
        this.getAllNotes(n.id);
      }
    }
  },
  methods: {
    editNote(item, selectedNote) {
      this.openNoteDialog = false;
      if (item.noteType == 1 || item.noteType == 3) this.openNoteFormDialogEdit(item.index);
      else {
        this.selectedNote = selectedNote;
        setTimeout(() => (this.openNoteDialog = true), 0);
      }
    },
    async getNotes(n) {
      let params = {
        caseId: this.caseSelectedData.id,
        pageNumber: n,
        pageSize: 10
      };
      this.loadingNotes = true;
      try {
        await this.$store.dispatch('getNotes', params);
      } finally {
        this.loadingNotes = false;
      }
    },
    async getAllNotes(caseId) {
      let paramsPageSize = {
        caseId: caseId
      };
      let params = {
        caseId: caseId,
        pageNumber: 1,
        pageSize: 10
      };
      this.loadingNotes = true;
      try {
        await this.$store.dispatch('getNotesPageSize', paramsPageSize);
        await this.$store.dispatch('getNotes', params);
      } finally {
        this.loadingNotes = false;
      }
    },
    openNoteFormDialog() {
      this.dialogNote = true;
      this.currentNoteData = null;
      this.newNote = true;
    },
    openNoteFormDialogEdit(index) {
      this.dialogNote = true;
      this.currentNoteData = this.selectedCaseNotes[index];
      this.newNote = false;
    },
    closeDialog() {
      this.dialogNote = false;
    }
  }
};
</script>

<style lang="stylus"></style>