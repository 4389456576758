<template>
  <v-card class="elevation-1 bg-whitesmoke mb-8 dcc-block-wrapper">
    <!-- APPBAR -->
    <v-app-bar class="elevation-0 bg-whitesmoke app-bar align-center">
      <v-toolbar-title class="primary--text text-uppercase subtitle-1 font-weight-medium">
        <div class="d-flex align-center">
          <div class="icon-border-custom border-primary me-2" style="border-color: var(--v-primary-base)">
            <v-icon color="primary" left>mdi-file-certificate</v-icon>
          </div>
          DCC Info
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn fab v-on="on" elevation="0" @click="getDccData()" color="transparent" :loading="dataLoading" :disabled="dataLoading">
            <v-icon color="darkgray">mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" fab class="subject-menu-btn">
            <v-icon large class="mx-auto">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item>
            <v-list-item-subtitle> Request DCC Certificate </v-list-item-subtitle>
          </v-list-item>
          <v-list-item @click="requestDccCertificate(dccTypes.CERT_VACCINE.key)">
            <v-list-content>
              <v-list-item-title>
                <v-icon left>{{ dccTypes.CERT_VACCINE.icon }}</v-icon
                >Vaccine
              </v-list-item-title>
            </v-list-content>
          </v-list-item>
          <v-list-item @click="requestDccCertificate(dccTypes.CERT_TEST.key)">
            <v-list-content>
              <v-list-item-title>
                <v-icon left>{{ dccTypes.CERT_TEST.icon }}</v-icon
                >Testing
              </v-list-item-title>
            </v-list-content>
          </v-list-item>
          <v-list-item @click="requestDccCertificate(dccTypes.CERT_RECOVERY.key)">
            <v-list-content>
              <v-list-item-title>
                <v-icon left>{{ dccTypes.CERT_RECOVERY.icon }}</v-icon
                >Recovery
              </v-list-item-title>
            </v-list-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- DCC INFO DATA -->
    <div class="white">
      <div v-if="dataLoading" class="pa-4">
        <v-skeleton-loader
          v-for="(page, i) in 2"
          :key="'skeletonloader-' + i"
          type="list-item"
          ref="skeleton"
          class="mx-auto my-0"
        ></v-skeleton-loader>
      </div>
      <div v-if="dccData && !dataLoading">
        <v-simple-table class="ma-0 pa-0 dcc-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Type</th>
                <th>Signed</th>
                <th>Expired</th>
                <th>Request</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dccItem, i) in dccData" :key="dccItem.key">
                <td class="caption">
                  <v-icon small class="me-1">{{ dccTypes[dccItem.certDataType].icon }}</v-icon
                  >{{ dccTypes[dccItem.certDataType].text }}
                </td>
                <td class="caption">
                  <template v-if="dccItem.signedDate">
                    {{ formatDate(dccItem.signedDate) }} <br />
                    {{ formatTime(dccItem.signedDate) }}
                  </template>
                  <template v-else-if="dccItem.certState == 'SIGN_REQUEST'">Pending</template>
                </td>
                <td class="caption">
                  <template v-if="dccItem.expireDate">
                    {{ formatDate(dccItem.expireDate) }} <br />
                    {{ formatTime(dccItem.expireDate) }}
                  </template>
                  <template v-else-if="dccItem.certState == 'SIGN_REQUEST'">Pending</template>
                </td>
                <td>
                  <template v-if="dccItem.certType == 'CERT_APP'">
                    <v-text small outlined class="caption" color="info"> App</v-text>
                  </template>
                  <template v-if="dccItem.certType == 'CERT_PAPER'">
                    <v-text small outlined class="caption"> Paper</v-text>
                    <v-tooltip top v-if="dccTypes[dccItem.certDataType].key === 'CERT_VACCINE'">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="transparent"
                          v-if="dccItem.certState == 'SIGN_VALID' && dccItem.certType == 'CERT_PAPER'"
                          v-bind="attrs"
                          @click="downloadCertificate(i, dccItem.id)"
                          small
                          fab
                          :loading="dccItem.downloading"
                          :disabled="dccItem.downloading"
                          elevation="0"
                          class="mx-1"
                        >
                          <v-icon class="pa-0">mdi-cloud-download-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Download certificate</span>
                    </v-tooltip>
                  </template>
                </td>
                <td>
                  <v-chip small :color="dccStates[dccItem.certState].color" class="font-weight-medium">
                    {{ dccStates[dccItem.certState].text }}
                  </v-chip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div v-else-if="!dataLoading" class="no-data py-8 px-2">No Data</div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import { dccStates, dccTypes } from '@/store/data/dcc-states.js';

export default {
  mixins: [SharedFunctions],
  data() {
    return {
      dataLoading: false,
      dccData: null,
      dccStates: dccStates,
      dccTypes: dccTypes,
      downloadingCert: false
    };
  },
  computed: {
    ...mapGetters(['caseSelectedData']),
    currentCaseId() {
      return this.caseSelectedData.id;
    }
  },
  watch: {
    currentCaseId(n, o) {
      if (n && n != o) {
        this.getDccData();
      }
    }
  },
  methods: {
    async requestDccCertificate(dataType) {
      console.log(dataType);
      this.dataLoading = true;
      try {
        const dccData = await this.$store.dispatch('requestDcc', { caseId: this.currentCaseId, dataType: dataType });
        if (dccData) this.getDccData();
      } finally {
        this.dataLoading = false;
      }
    },
    getDccData() {
      this.dataLoading = true;
      this.$store
        .dispatch('getDccData', this.currentCaseId)
        .then((data) => {
          if (!data) {
            this.dccData = null;
            return;
          }
          this.dccData = data.map((item) => {
            return {
              ...item,
              downloading: false,
              loading: false
            };
          });
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    async downloadCertificate(index, dccId) {
      console.log(dccId);
      this.dccData[index].downloading = true;
      this.downloadingCert = true;
      await this.$store.dispatch('downloadDccCertificate', { dccId: dccId, certId: this.dccData.id });
      this.dccData[index].downloading = false;
    }
  }
};
</script>

<style lang="scss">
.dcc-block-wrapper {
  .dcc-table {
    tbody {
      tr:hover {
        background-color: transparent !important;
      }
    }
  }
  .no-data {
    padding: 20px;
    text-align: center;
  }
}
</style>