<template>
  <div>
    <!-- dialog -->
    <v-dialog v-model="showTravelInfoDialog" persistent max-width="800" scrollable>
      <v-card class="received-form">
        <v-card-title class="headline primary white--text" primary-title>
          {{ dataFromParent ? 'Travel data: ' + dataFromParent['travelFlightNumber'] : 'Create travel info item' }}
          <v-spacer />
          <v-btn v-if="dataFromParent" class="me-2" color="white " :fab="!editMode" text small @click="setEditMode()">
            <v-icon v-if="!editMode">mdi-pencil</v-icon>
            <span v-else>cancel edit</span>
          </v-btn>
          <v-btn color="white mt-0" fab text small @click="$emit('closeModal')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <!-- FROM -->
        <v-card-text class="my-4" v-if="!dataFromParent || editMode">
          <v-row v-if="!editMode">
            <v-col cols="12" md="10" offset-md="1">
              <v-card outlined style="background: #f9f9f9">
                <v-card-text>
                  <div class="mb-4 font-weight-medium">Prefill form with ED form data</div>
                  <v-form v-model="formEdValid" ref="form-check-ed-number" @submit.prevent="checkEdNumber()">
                    <v-text-field
                      :disabled="loadingEd"
                      dense
                      hide-details
                      outlined
                      v-model="edNumber"
                      label="ED Card number"
                      background-color="white"
                    ></v-text-field>

                    <div class="d-flex">
                      <v-spacer></v-spacer>
                      <v-btn class="mt-4" type="submit" outlined small color="primary" :disabled="loadingEd" :loading="loadingEd">
                        Check ED number
                      </v-btn>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-form v-model="formTravelValid" ref="form-create-travel-info">
            <!-- arrvival departure -->
            <v-row>
              <v-col cols="12" md="5" offset-md="1">
                <DatePicker
                  :disabled="hasLinkedEd"
                  :valueFromParent="formData.travelArrivalDate"
                  label="Travel Arrival Date"
                  keyName="travelArrivalDate"
                  :required="true"
                  @updateParentValue="updateValue"
                />
              </v-col>
              <v-col cols="12" md="5">
                <DatePicker
                  :disabled="hasLinkedEd"
                  :valueFromParent="formData.travelDeparture"
                  label="Travel Departure Date"
                  keyName="travelDeparture"
                  :required="true"
                  @updateParentValue="updateValue"
                />
              </v-col>
            </v-row>
            <!-- flight number / accomodation address  -->
            <v-row>
              <v-col cols="12" md="5" offset-md="1">
                <v-text-field
                  :disabled="hasLinkedEd"
                  v-model="formData.travelFlightNumber"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Flight Number"
                  hint="AA1234"
                  class="required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  :disabled="hasLinkedEd"
                  v-model="formData.accommodationAddress"
                  label="Host Hotel (accommodation location):"
                  hint="eg. Marriot Aruba"
                  :rules="[(v) => !!v || 'This field is required']"
                  class="required"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- travel origin -->
            <v-row>
              <v-col cols="12" md="5" offset-md="1">
                <v-text-field
                  :disabled="hasLinkedEd"
                  v-model="formData.travelOrigin"
                  label="Travel Origin"
                  hint="eg. New York"
                  :rules="[(v) => !!v || 'This field is required']"
                  class="required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" v-if="editMode">
                <v-text-field
                  v-model="formData.linkedEdcardId"
                  label="Linked ED card"
                  :rules="edCardInputRules"
                  :class="hasLinkedEd ? 'required' : ''"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <!-- DISPLAY DATA -->
        <v-card-text v-if="displayData && !editMode">
          <v-row>
            <v-col cols="12" md="8" offset-md="2">
              <v-simple-table class="ma-0 pa-0">
                <template v-slot:default>
                  <tbody>
                    <tr v-for="(item, i) in displayData" :key="i + '-travel-data'">
                      <template v-if="i == 'Status'">
                        <td class="caption">{{ i }}</td>
                        <td>
                          <v-chip small :color="ahaApprovedStates[item].color" class="" outlined>
                            {{ ahaApprovedStates[item].text }}
                          </v-chip>
                        </td>
                      </template>
                      <template v-else>
                        <td class="caption">{{ i }}</td>
                        <td class="font-weight-medium">{{ item }}</td>
                      </template>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions v-if="!dataFromParent">
          <v-spacer />
          <v-btn color="primary" text @click="$emit('closeModal')" :disabled="loadingTravel"> Cancel </v-btn>
          <v-btn color="primary" @click="saveTravelInfo()" :disabled="loadingTravel" :loading="loadingTravel" elevation="0"> Save </v-btn>
        </v-card-actions>
        <v-card-actions v-else-if="editMode">
          <v-spacer />
          <v-btn color="primary" text @click="$emit('closeModal')" :disabled="loadingTravel"> Cancel </v-btn>
          <v-btn color="primary" @click="updateTravelInfo()" :disabled="loadingTravel" :loading="loadingTravel" elevation="0"> Update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- print registration component -->
    <PrintSubject :formDataParent="formDataCase" :patientCase="patientCase" :registrationType="registrationType" id="printSubjectTravelModal" />
  </div>
</template>

<script>
import DatePicker from '@/components/form-components/date-picker.vue';
import { twentyCountArray } from '@/utils/counts.js';
import { mapGetters } from 'vuex';
import ahaApprovedStates from '@/store/data/aha-approved-states.json';
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import PrintSubject from '@/views/VisitorScanPage/components/PrintSubject.vue';

export default {
  mixins: [SharedFunctions],
  components: {
    DatePicker,
    PrintSubject
  },
  props: ['showTravelInfoDialog', 'dataFromParent'],
  computed: {
    ...mapGetters(['caseSelectedData'])
  },
  data() {
    return {
      formData: {
        travelOrigin: null,
        travelArrivalDate: null,
        travelDeparture: null,
        travelFlightNumber: null,
        //travelSize: null,
        accommodationAddress: null,
        linkedEdcardId: null
      },
      twenytCountArray: twentyCountArray(),
      edNumber: null,
      loadingEd: false,
      loadingTravel: false,
      ahaApprovedStates: ahaApprovedStates,
      editMode: false,
      displayData: null,
      edCardInputRules: [],
      hasLinkedEd: false,
      registrationType: null,
      formDataCase: null
    };
  },
  created() {
    if (this.dataFromParent) {
      this.displayData = this.createDisplayData(this.dataFromParent);
      if (this.dataFromParent.linkedEdcardId) {
        this.hasLinkedEd = true;
        this.edCardInputRules = [(v) => !!v || 'This field is required'];
      }
    }
  },
  methods: {
    updateValue(value, keyName) {
      if (this.formData[keyName] === undefined) {
        this.$set(this.formData, keyName, null);
      }
      this.formData[keyName] = value;
    },
    saveTravelInfo() {
      if (!this.$refs['form-create-travel-info'].validate()) return;
      this.loadingTravel = true;
      let formData = this.cleanFormData(JSON.parse(JSON.stringify(this.formData)));
      let dataToPost = {
        id: this.caseSelectedData.id,
        body: formData
      };
      this.$store
        .dispatch('createCaseTravelItem', dataToPost)
        .then((createdSuccessfully) => {
          if (createdSuccessfully) {
            this.$emit('getTravelData', { patientCaseId: this.caseSelectedData.id, done: null });
            this.$emit('closeModal');
            this.edNumber = null;
          }
        })
        .finally(() => {
          this.loadingTravel = false;
        });
    },
    checkEdNumber() {
      if (!this.edNumber) return;
      this.loadingEd = true;
      let dataToPost = {
        id: this.caseSelectedData.id,
        body: {
          edCardId: this.edNumber
        }
      };
      this.$store
        .dispatch('checkEdCardData', dataToPost)
        .then((data) => {
          this.formData = {
            travelOrigin: data.travelOrigin,
            travelArrivalDate: data.travelArrival,
            travelDeparture: data.travelDeparture,
            travelFlightNumber: data.travelFlightNumber,
            accommodationAddress: data.accommodationAddress,
            linkedEdcardId: data.edCardId
          };
        })
        .finally(() => (this.loadingEd = false));
    },
    cleanFormData(formData) {
      if (!formData.linkedEdcardId) {
        delete formData.linkedEdcardId;
      }
      return formData;
    },
    createDisplayData(dataItem) {
      let item = JSON.parse(JSON.stringify(dataItem));
      delete item.travelSize;
      delete item.insertDate;
      delete item.id;
      delete item.travelFrom;

      item.travelArrivalDate = this.formatDate(item.travelArrivalDate);
      item.travelDeparture = this.formatDate(item.travelDeparture);

      item = this.renameObjectKey(item, 'healthApproval', 'Status');
      item = this.renameObjectKey(item, 'accommodationAddress', 'Accommodation Address');
      item = this.renameObjectKey(item, 'linkedEdcardId', 'ED card number');
      item = this.renameObjectKey(item, 'travelArrivalDate', 'Arrival Date');
      item = this.renameObjectKey(item, 'travelDeparture', 'Departure Date');
      item = this.renameObjectKey(item, 'travelOrigin', 'Travel Origin');
      item = this.renameObjectKey(item, 'travelFlightNumber', 'Travel Flight Number');

      return item;
    },
    renameObjectKey(object, oldKey, newKey) {
      object[newKey] = object[oldKey];
      delete object[oldKey];
      return object;
    },
    setEditMode() {
      this.editMode = !this.editMode;
      this.formData = JSON.parse(JSON.stringify(this.dataFromParent));
    },
    updateTravelInfo() {
      if (!this.$refs['form-create-travel-info'].validate()) return;
      this.loadingTravel = true;
      let formData = this.cleanFormData(JSON.parse(JSON.stringify(this.formData)));
      let dataToPost = {
        id: this.dataFromParent.id,
        body: formData
      };
      this.$store
        .dispatch('updateCaseTravelItem', dataToPost)
        .then((createdSuccessfully) => {
          if (createdSuccessfully) {
            this.$emit('getTravelData', {
              patientCaseId: this.caseSelectedData.id,
              done: () => {
                this.editMode = false;
                this.displayData = this.createDisplayData(this.dataFromParent);
                this.$store.commit('SET_SNACKBAR', {
                  open: true,
                  text: 'Travel info updated',
                  color: 'success'
                });
              }
            });
          }
        })
        .finally(() => {
          this.loadingTravel = false;
        });
    }
  }
};
</script>

<style>
</style>