<template>
  <div>
    <v-card class="elevation-1 bg-whitesmoke mb-8 test-kit-card">
      <!-- APPBAR -->
      <v-app-bar class="elevation-0 bg-whitesmoke app-bar align-center">
        <v-toolbar-title class="primary--text text-uppercase subtitle-1 font-weight-medium">
          <div class="d-flex align-center">
            <div class="icon-border-custom border-primary me-2" style="border-color: var(--v-primary-base)">
              <v-icon color="primary" left>mdi-airplane</v-icon>
            </div>
            Travel Info
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom left offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" fab class="subject-menu-btn">
              <v-icon large class="mx-auto">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="showTravelInfoDialog = true">
              <v-list-content>
                <v-list-item-title> <v-icon left>mdi-airplane</v-icon>Create travel info entry </v-list-item-title>
              </v-list-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <!-- FLIGHT INFO DATA -->
      <div class="white">
        <div v-if="dataLoading" class="pa-4">
          <v-skeleton-loader
            v-for="(page, i) in 3"
            :key="'skeletonloader-' + i"
            type="list-item"
            ref="skeleton"
            class="mx-auto my-0"
          ></v-skeleton-loader>
        </div>
        <div v-if="travelData && travelData.length > 0 && !dataLoading">
          <v-simple-table class="ma-0 pa-0 flights-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Flight Number</th>
                  <th>Arival</th>
                  <th>Departure</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in travelData" :key="item.id" @click="openTravelData(item)">
                  <td>{{ item.travelFlightNumber }}</td>
                  <td>{{ formatDate(item.travelArrivalDate) }}</td>
                  <td>{{ formatDate(item.travelDeparture) }}</td>
                  <td>
                    <v-chip small :color="ahaApprovedStates[item.healthApproval].color" class="font-weight-medium" outlined>
                      {{ ahaApprovedStates[item.healthApproval].text }}
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div v-else-if="!dataLoading" class="no-data py-8 px-2">No Data</div>
      </div>
    </v-card>
    <TravelInfoDialog
      v-if="showTravelInfoDialog"
      :showTravelInfoDialog="showTravelInfoDialog"
      :dataFromParent="displayDataModal"
      @closeModal="closeTravelModal"
      @getTravelData="getAllTravelData"
    />
  </div>
</template>

<script>
import TravelInfoDialog from '@/views/AccountPage/TravelInfoBlock/components/TravelInfoDialog.vue';
import { mapGetters } from 'vuex';
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import ahaApprovedStates from '@/store/data/aha-approved-states.json';

export default {
  mixins: [SharedFunctions],
  components: {
    TravelInfoDialog
  },
  computed: {
    ...mapGetters(['caseSelectedData']),
    currentCaseId() {
      return this.caseSelectedData.id;
    }
  },
  data() {
    return {
      dataLoading: false,
      showTravelInfoDialog: false,
      travelData: [],
      displayDataModal: null,
      ahaApprovedStates: ahaApprovedStates
    };
  },
  watch: {
    currentCaseId(n, o) {
      if (n && n != o) {
        this.getAllTravelData({ patientCaseId: n, done: null });
      }
    }
  },
  methods: {
    closeTravelModal() {
      this.displayDataModal = null;
      this.showTravelInfoDialog = false;
    },
    getAllTravelData(payload) {
      this.dataLoading = true;
      this.$store
        .dispatch('getAllCaseTravelItems', payload.patientCaseId)
        .then((data) => {
          this.travelData = data;
          if (payload.done) {
            const selectedItem = data.filter((item) => {
              return this.displayDataModal.id == item.id;
            });
            this.displayDataModal = selectedItem[0];
            setTimeout(() => {
              payload.done();
            }, 100);
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    openTravelData(dataItem) {
      this.displayDataModal = JSON.parse(JSON.stringify(dataItem));
      this.showTravelInfoDialog = true;
    }
  }
};
</script>

<style lang="scss">
.flights-table {
  tr {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>