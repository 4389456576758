<template>
  <v-dialog v-model="dialogUser" width="800" scrollable persistent>
    <v-card>
      <v-card-title class="headline primary white--text" primary-title> Edit Account </v-card-title>

      <v-card-text>
        <AccountForm :newUser="false" v-if="dialogUser" ref="userFormComponent" :editUser="true" />
      </v-card-text>

      <v-divider></v-divider>

      <v-alert type="error" v-if="dialogError" dense dismissible> Please fill in all required fields </v-alert>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeModal()"> Cancel </v-btn>
        <v-btn color="primary" @click="editUser()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AccountForm from '@/components/account-and-case-components/account-form.vue';
export default {
  props: ['dialogUser'],
  components: {
    AccountForm
  },
  data() {
    return {
      dialogError: false
    };
  },
  computed: {
    ...mapGetters(['accountData'])
  },
  watch: {
    dialogUser(n, o) {
      if (n != o) {
        this.$emit('dialogUserChanged', n);
      }
    }
  },
  created() {},
  methods: {
    closeModal() {
      this.$parent.dialogUser = false;
    },
    editUser() {
      if (this.$refs.userFormComponent.$refs['form-account'].validate()) {
        this.dialogError = false;
        let payload = this.createPayloadObj();
        this.$store.dispatch('editUser', payload).then(async (data) => {
          if (data.status == 200 || data.status == 204) {
            await this.$store.dispatch('getAccountData', this.$route.params.accountId);
            this.closeModal();
          }
        });
      } else {
        this.dialogError = true;
      }
    },
    createPayloadObj() {
      let subject = this.accountData;
      let payload = {
        id: subject.keycloakId,
        email: this.$refs.userFormComponent.formData.username,
        firstName: this.$refs.userFormComponent.formData.firstName,
        lastName: this.$refs.userFormComponent.formData.lastName
      };

      return payload;
    }
  }
};
</script>

<style>
</style>