<template>
  <v-dialog v-model="dialogRemoveVaccine" width="600" scrollable persistent>
    <v-card>
      <v-card-title class="headline" error-title> Remove Appointment </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-4">
        <h3>Are you sure you want to remove the user's vaccine appointment?</h3>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$parent.dialogRemoveVaccine = false" :disabled="removingAppointment"> Cancel </v-btn>
        <v-btn color="error" @click="removeVaccineAppointment()" :loading="removingAppointment" :disabled="removingAppointment"> Yes </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import axios from 'axios';
export default {
  mixins: [SharedFunctions],
  props: ['vaccineId', 'dialogRemoveVaccine', 'caseSelectedData'],
  data() {
    return {
      removingAppointment: false
    };
  },
  created() {},
  methods: {
    async removeVaccineAppointment() {
      try {
        this.removingAppointment = true;
        const res = await axios.delete(`${this.$store.state.baseUrlApi}/healthcheck-restful-api/vaccine/${this.vaccineId}`);
        if (res.status == 204 || res.status == 200) {
          this.$parent.dialogRemoveVaccine = false;
          this.$store.commit('SET_SNACKBAR', { open: true, text: 'Appointment successfully removed.', color: 'success' });
          this.$emit('initVaccineDataParent', this.caseSelectedData.id);
        }
      } catch (error) {
        console.log(error);
        this.$store.commit('SET_SNACKBAR', { open: true, text: 'Error removing vaccine appointment', color: 'error' });
      } finally {
        this.removingAppointment = false;
      }
    }
  }
};
</script>