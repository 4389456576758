export const dccStates = {
  SIGN_REQUEST: {
    text: 'Pending',
    color: 'warning'
  },
  SIGN_VALID: {
    text: 'Valid',
    color: 'success'
  },
  DVG_APPROVED: {
    text: 'Expired',
    color: 'error'
  },
  REJECTED: {
    text: 'Rejected',
    color: 'error'
  },
  SIGN_EXPIRED: {
    text: 'Expired',
    color: 'error'
  }
};

export const dccTypes = {
  CERT_VACCINE: {
    text: 'Vaccine',
    key: 'CERT_VACCINE',
    icon: 'mdi-needle'
  },
  CERT_TEST: {
    text: 'Testing',
    key: 'CERT_TEST',
    icon: 'mdi-test-tube'
  },
  CERT_RECOVERY: {
    text: 'Recovery',
    key: 'CERT_RECOVERY',
    icon: 'mdi-medical-bag'
  }
};
